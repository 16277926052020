import axios from 'axios';
import { format, sub } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import { Container } from '../../imports';

import LoadingPage from '../../components/LoadingPage';

import { ApiDashboardBookingGet, ApiDashboardSalesGet } from '../../constants/endpoints';
import { DateFormatApi } from '../../constants/utils';
import DashboardItem from '../../models/dashboard/DashboardItem';

import { useAuth } from '../../services/useAuth';
import useAbortSignal from '../../services/useAbortSignal';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import { convert_to_dashboard_array } from '../../utils/utils';

export default function Dashboard() {
  const [data, setData] = useState<DashboardItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useAuth();
  const { checkResponseError } = useDataSendStatus();
  const abortSignal = useAbortSignal();

  const loadFormData = useCallback(() => {
    setLoading(true);

    const today = new Date();
    const past = sub(today, { days: 7 });
    let requestParams = [format(past, DateFormatApi), format(today, DateFormatApi)];

    const encodedRequestParams: string = '/' + window.btoa(requestParams.join(','));

    Promise.all([
      axios.get(ApiDashboardBookingGet + encodedRequestParams, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      }),
      axios.get(ApiDashboardSalesGet + encodedRequestParams, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      }),
    ])
      .then((response) => {
        setData(convert_to_dashboard_array(response[0].data.data, response[1].data.data));
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [user.token, abortSignal, checkResponseError]);

  useEffect(() => {
    loadFormData();
  }, [loadFormData]);

  return (
    <Container maxWidth="lg">
      {loading && <LoadingPage />}
      {!loading && (
        <LineChart
          width={1000}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="views" stroke="#82ca9d" />
          <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      )}
    </Container>
  );
}
