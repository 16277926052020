import { DragEvent, useRef } from 'react';

import { Avatar, Card, CheckIcon, Chip, ClearIcon, styled } from '../../../imports';
import FunctionalitiesData from '../../../models/functionalities/FunctionalitiesData';

type TransferListData = Pick<FunctionalitiesData, 'crsPriorityList'>;

type TransferListProp = {
  name: string;
  items: string[];
  selected: string[];
  updateFields: (fields: Partial<TransferListData>) => void;
};

export default function TransferList(props: TransferListProp) {
  const dragPlace1 = useRef<boolean | null>(null as boolean | null);
  const dragItem1 = useRef<number | null>(null as number | null);
  const dragOverItem1 = useRef<number | null>(null as number | null);

  const dragPlace2 = useRef<boolean | null>(null as boolean | null);
  const dragItem2 = useRef<number | null>(null as number | null);

  const List = styled('ul')(() => ({
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    minHeight: '3rem',
    margin: 0,
    padding: '0.5rem',
    borderColor: 'darkgray',
    borderRadius: '0.3rem',
  }));

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
    '& .MuiChip-root': {
      cursor: 'pointer',
    },
  }));

  const ucfirst = (value: string): string => {
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
  };

  const dragStart1 = (position: number) => {
    dragItem1.current = position;
  };

  const dragEnter1 = (position: number) => {
    dragOverItem1.current = position;
  };

  const dragPlaceEnter1 = () => {
    dragPlace1.current = true;
    dragPlace2.current = false;
  };

  const dragStart2 = (position: number) => {
    dragItem2.current = position;
  };

  const dragPlaceEnter2 = () => {
    dragPlace1.current = false;
    dragPlace2.current = true;
  };

  const dropPlaceEnd1 = () => {
    if (dragPlace1.current) {
      if (dragItem1.current !== dragOverItem1.current) {
        const copyListItems = [...props.selected];
        const dragItemContent = copyListItems[dragItem1.current as number];
        copyListItems.splice(dragItem1.current as number, 1);
        copyListItems.splice(dragOverItem1.current as number, 0, dragItemContent);
        dragItem1.current = null;
        dragOverItem1.current = null;
        props.updateFields({ crsPriorityList: copyListItems });
      }
    } else {
      const copyListItems = [...props.selected];
      copyListItems.splice(dragItem1.current as number, 1);
      dragItem1.current = null;
      dragOverItem1.current = null;
      props.updateFields({ crsPriorityList: copyListItems });
    }
  };

  const dropPlaceEnd2 = () => {
    if (dragPlace1.current) {
      const copyListItems = [...props.selected];
      const dragItemContent = props.items.filter((item: string) => {
        return props.selected.indexOf(item) === -1;
      })[dragItem2.current as number];
      copyListItems.splice(dragOverItem1.current as number, 0, dragItemContent);
      dragItem1.current = null;
      dragOverItem1.current = null;
      props.updateFields({ crsPriorityList: copyListItems });
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        onDragEnter={() => dragPlaceEnter1()}
        onDragOver={(event: DragEvent) => event.preventDefault()}
        onDragEnd={() => dropPlaceEnd1()}>
        <List>
          {props.selected &&
            props.selected.map((crsItem: string, index: number) => {
              return (
                <ListItem
                  key={props.name + '-' + crsItem}
                  draggable
                  onDragStart={() => dragStart1(index)}
                  onDragEnter={() => dragEnter1(index)}
                  onDragOver={(event: DragEvent<HTMLLIElement>) => event.preventDefault()}>
                  <Chip
                    label={ucfirst(crsItem)}
                    avatar={<Avatar>{index + 1}</Avatar>}
                    color="primary"
                    variant="transfer"
                    deleteIcon={<ClearIcon />}
                    onDelete={() =>
                      props.updateFields({ crsPriorityList: props.selected.filter((item: string) => item !== crsItem) })
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </Card>
      <Card
        elevation={0}
        onDragEnter={() => dragPlaceEnter2()}
        onDragOver={(event: DragEvent) => event.preventDefault()}
        onDragEnd={() => dropPlaceEnd2()}>
        <List>
          {props.items
            .filter((item: string) => {
              return props.selected.indexOf(item) === -1;
            })
            .map((item: string, index: number) => {
              return (
                <ListItem
                  key={props.name + '-' + item}
                  draggable
                  onDragStart={() => dragStart2(index)}
                  onDragOver={(event: DragEvent<HTMLLIElement>) => event.preventDefault()}>
                  <Chip
                    label={ucfirst(item)}
                    color="secondary"
                    variant="transfer"
                    deleteIcon={<CheckIcon />}
                    onDelete={() => props.updateFields({ crsPriorityList: [...props.selected, item] })}
                  />
                </ListItem>
              );
            })}
        </List>
      </Card>
    </>
  );
}
