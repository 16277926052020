import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TextField, TextFieldProps } from '../imports';

type NumberFieldProp = {
  value?: string;
  minValue?: number;
  minValueErrorText?: string;
  maxValue?: number;
  maxValueErrorText?: string;
  onlyInteger?: boolean;
  onChange?: (event: ChangeEvent | FocusEvent, value: number | null) => void;
  bulkChange?: boolean;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

export default function NumberField({
  minValue,
  minValueErrorText,
  maxValue,
  maxValueErrorText,
  onlyInteger,
  onChange,
  bulkChange = true,
  ...props
}: NumberFieldProp) {
  const isFirstRender = useRef(true);
  const [error, setError] = useState<boolean>(props.error ?? false);
  const [errorText, setErrorText] = useState<string>('');
  const [value, setValue] = useState<string>(props.value ?? '');

  const regex = useMemo(() => (onlyInteger ? /^-?[0-9]*$/ : /^-?[0-9]*\.?[0-9]*$/), [onlyInteger]);

  const getNumber = useCallback(
    (value: string): number => (onlyInteger ? parseInt(value, 10) : parseFloat(value)),
    [onlyInteger]
  );

  const validate = useCallback(
    (value: string): void => {
      setError(false);
      setErrorText('');

      if (!props.disabled && value.match(regex)) {
        const newValue: number = getNumber(value);
        if (!isNaN(newValue)) {
          if (minValue !== undefined && newValue < minValue) {
            setError(true);
            setErrorText(minValueErrorText ?? '');
          } else if (maxValue !== undefined && newValue > maxValue) {
            setError(true);
            setErrorText(maxValueErrorText ?? '');
          }
        }
      }
    },
    [getNumber, maxValue, maxValueErrorText, minValue, minValueErrorText, props.disabled, regex]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    validate(props.value ?? '');
    setValue(props.disabled ? '' : props.value ?? '');
  }, [props.disabled, props.value, validate]);

  return (
    <TextField
      {...props}
      {...(error && { error: true, helperText: errorText })}
      value={value}
      onBlur={(event) => {
        if (event.target.value.match(regex)) {
          if (bulkChange) {
            const v = getNumber(value);
            onChange?.(event, isNaN(v) ? null : v);
          }
        }
      }}
      onChange={(event) => {
        if (event.target.value.match(regex)) {
          validate(event.target.value);
          setValue(event.target.value);

          if (!bulkChange) {
            const v = getNumber(value);
            onChange?.(event, isNaN(v) ? null : v);
          }
        }
      }}
    />
  );
}
