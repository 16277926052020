import TravelAgencyNumberFunctionalities from '../models/TravelAgencyNumberFunctionalities';

export const TravelAgencyNumberFunctionalitiesInit: TravelAgencyNumberFunctionalities = {
  seatDisplayEnabled: false,
  seatReservationNonChargeableEnabled: false,
  seatReservationChargeableEnabled: false,
  extrasEnabled: false,
  cashUpgradeEnabled: false,
  baggageEnabled: false,
  mealEnabled: false,
  insuranceBlueribbonbagsEnabled: false,
  transferDistribusionEnabled: false,
};
