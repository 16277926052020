import { useState } from 'react';
import { format } from 'date-fns';

import {
  AdapterDateFns,
  CancelIcon,
  DatePicker,
  DateValidationError,
  Grid,
  IconButton,
  InputAdornment,
  LocalizationProvider,
  TextField,
  TextFieldProps,
} from '../../../imports';

import { DateFormatApi, DateFormatInput } from '../../../constants/utils';
import PreferencesItemData from '../../../models/preferences/PreferencesItemData';

interface PreferencesMessageItemProp {
  message: PreferencesItemData;
  onChange: (message: PreferencesItemData) => void;
}

export default function PreferencesMessageItem({ message, onChange }: PreferencesMessageItemProp) {
  const fromValue: Date | null = message.from ? new Date(message.from) : null;
  const [fromError, setFromError] = useState<boolean>(false);
  const [fromErrorText, setFromErrorText] = useState<string | null>(null);

  const toValue: Date | null = message.to ? new Date(message.to) : null;
  const [toError, setToError] = useState<boolean>(false);
  const [toErrorText, setToErrorText] = useState<string | null>(null);

  const handleChangeDate = (name: string, value: Date | null) => {
    let dateValue: string | null = null;

    if (value) {
      try {
        dateValue = format(value, DateFormatApi);
      } catch (e) {
        dateValue = null;
      }
    }

    onChange({ ...message, [name]: dateValue });
  };
  const handleChangeText = (event: any) => onChange({ ...message, [event.target.name]: event.target.value });
  const handleErrorFrom = (reason: DateValidationError) => {
    switch (reason) {
      case 'invalidDate':
        setFromError(true);
        setFromErrorText('Use a valid date format');
        break;
      case 'maxDate':
        if (toValue instanceof Date) {
          setToError(true);
          setToErrorText('Choose a date on or after ' + format(toValue as Date, DateFormatInput));
        }
        break;
      default:
        setFromError(false);
        setFromErrorText(null);
        break;
    }
  };
  const handleErrorTo = (reason: DateValidationError) => {
    switch (reason) {
      case 'invalidDate':
        setToError(true);
        setToErrorText('Use a valid date format');
        break;
      case 'minDate':
        if (fromValue instanceof Date) {
          setToError(true);
          setToErrorText('Choose a date on or before ' + format(fromValue as Date, DateFormatInput));
        }
        break;
      default:
        setToError(false);
        setToErrorText(null);
        break;
    }
  };
  const handleResetValue = () => onChange({ ...message, value: null });
  const handleResetFrom = (event: any): void => {
    event.preventDefault();

    if (event.key === 'Backspace' || event.key === 'Delete') {
      handleChangeDate('from', null);
    }
  };
  const handleResetTo = (event: any): void => {
    event.preventDefault();

    if (event.key === 'Backspace' || event.key === 'Delete') {
      handleChangeDate('to', null);
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={12}>
        <TextField
          name="value"
          label="Text"
          multiline
          rows={4}
          fullWidth
          value={message.value ?? ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ marginTop: 12 }}>
                <IconButton onClick={handleResetValue} edge="end">
                  <CancelIcon sx={{ color: 'primary.main' }} />
                </IconButton>
              </InputAdornment>
            ),
            sx: { alignItems: 'flex-start' },
          }}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date From"
            value={fromValue}
            onChange={(value) => handleChangeDate('from', value)}
            onError={handleErrorFrom}
            maxDate={toValue}
            inputFormat={DateFormatInput}
            renderInput={(params: TextFieldProps) => (
              <TextField
                name="from"
                error={fromError}
                helperText={fromError ? fromErrorText ?? undefined : undefined}
                fullWidth={true}
                onKeyDown={handleResetFrom}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date To"
            value={toValue}
            onChange={(value) => handleChangeDate('to', value)}
            onError={handleErrorTo}
            minDate={fromValue}
            inputFormat={DateFormatInput}
            renderInput={(params: TextFieldProps) => (
              <TextField
                name="to"
                error={toError}
                helperText={toError ? toErrorText ?? undefined : undefined}
                fullWidth={true}
                onKeyDown={handleResetTo}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
