import { createTheme } from '../imports';
import { DefaultTheme } from './Default';

import { leftMenuWidth } from '../constants/utils';

export const LeftMenuTheme = createTheme(
  {
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: leftMenuWidth,
            color: '#ffffff',
            borderRadius: 0,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            margin: DefaultTheme.spacing(2),
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#203c56',
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: '#ffffff',
            marginRight: DefaultTheme.spacing(2),
            minWidth: 0,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            marginTop: DefaultTheme.spacing(2),
            color: '#5a646e',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: DefaultTheme.spacing(2),
            borderColor: '#384044',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: '#ffffff',
            borderRadius: DefaultTheme.shape.borderRadius * 3,
          },
          notchedOutline: {
            borderColor: '#384044',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: '#ffffff',
          },
        },
      },
    },
  },
  DefaultTheme
);
