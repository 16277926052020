import { createRef, KeyboardEvent, useState } from 'react';

import { InputBase, Paper, SearchIcon } from '../imports';

import ListData from '../models/ListData';

interface ListFilterProps {
  filterCallBack: any;
  placeholder: string;
}

export function filterData<T extends ListData>(data: T[], filter: string) {
  return data.filter((item) => {
    return filter === '' || item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  });
}

export default function ListFilter(props: ListFilterProps) {
  const [, setFilter] = useState('');
  const searchInput = createRef<HTMLInputElement>();

  const setFilterName = (name: string) => {
    setFilter(name);
    props.filterCallBack(name);
  };

  const searchOnKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const char = event.key;
    const val = searchInput.current?.value as string;

    if (val.length > 1 && char.match(/^[\w.-]$/)) {
      setFilterName(val + char);
    } else if (val.length < 4) {
      setFilterName('');
    }
  };

  return (
    <Paper variant="outlined" sx={{ my: 2, display: 'flex', alignItems: 'center', width: '400px' }}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={props.placeholder}
        inputRef={searchInput}
        onKeyDown={(event) => searchOnKeyDown(event)}
      />
      <SearchIcon sx={{ color: '#0000001f' }} />
    </Paper>
  );
}
