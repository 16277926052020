import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import axios from 'axios';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
} from '../../imports';

import SimpleNumberField from '../../components/SimpleNumberField';
import LoadingPage from '../../components/LoadingPage';
import LoadingButton from '../../components/LoadingButton';
import HistoryCard from '../../components/HistoryCard';

import { ConsolidatorEdit } from '../../models/consolidator/ConsolidatorEdit';

import { ConsolidatorEditInit } from '../../constants/ConsolidatorEditInit';
import { ApiConsolidators } from '../../constants/endpoints';
import { DataSendStatusInit } from '../../constants/DataSendStatus/DataSendStatusInit';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useSecondaryMenu from '../../services/useSecondaryMenu';
import useAbortSignal from '../../services/useAbortSignal';

import { ucfirst } from '../../utils/utils';

export default function Consolidators() {
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [loadedFormData, setLoadedFormData] = useState<ConsolidatorEdit>(ConsolidatorEditInit);
  const [formData, setFormData] = useState<ConsolidatorEdit>(ConsolidatorEditInit);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const secondaryMenu = useSecondaryMenu();
  const abortSignal = useAbortSignal();

  const consolidatorId = secondaryMenu.selectedItems[0]?.value;

  function updateLoadedFormData(data: ConsolidatorEdit) {
    setLoadedFormData(data);
    setFormData(data);
  }

  const loadList = useCallback(() => {
    setLoading(true);

    axios
      .get(ApiConsolidators, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then((data) => {
        setLoading(false);

        secondaryMenu.setType('single');
        secondaryMenu.setSelectedItems([]);

        secondaryMenu.setItems(
          data.data.consolidators.map((consolidator: ConsolidatorEdit) => {
            return {
              name: consolidator.name ?? '',
              value: consolidator.id,
            };
          })
        );
      })
      .catch(checkResponseError);
  }, [abortSignal, checkResponseError, user.token]);

  const loadFormData = useCallback(() => {
    setLoading(true);

    axios
      .get(ApiConsolidators + '/' + consolidatorId, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then((data) => {
        setLoading(false);

        for (let key in data.data.consolidator) {
          if (data.data.consolidator[key] === null) {
            data.data.consolidator[key] = '';
          }
        }

        updateLoadedFormData(data.data.consolidator);
      })
      .catch(checkResponseError);
  }, [abortSignal, checkResponseError, consolidatorId, user.token]);

  useEffect(loadList, [loadList]);

  useEffect(() => consolidatorId ? loadFormData() : updateLoadedFormData(ConsolidatorEditInit), [consolidatorId, loadFormData]);

  function onSubmit(event: FormEvent<HTMLFormElement>){
    event.preventDefault();

    // reset before request
    setDataSendStatus(DataSendStatusInit);
    setSending(true);

    axios
      .put(ApiConsolidators + '/' + consolidatorId, formData, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then((data) => {
        const newItem = {
          name: data.data.consolidator.name,
          value: data.data.consolidator.id,
        };

        const newItems = [...secondaryMenu.items];

        newItems.splice(newItems.indexOf(secondaryMenu.selectedItems[0]), 1, newItem);

        secondaryMenu.setItems(newItems);
        secondaryMenu.setSelectedItems([newItem]);

        setDataSendStatus({ open: true, success: true, message: 'Data updated' });
        updateLoadedFormData(data.data.consolidator);
      })
      .catch(checkResponseError)
      .finally(() => setSending(false));
  }

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  function onSelectChange(e: SelectChangeEvent) {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  function onStatusChange(e: FormEvent<HTMLInputElement>) {
    setFormData({ ...formData, status: e.currentTarget.checked ? 'active' : 'inactive' });
  }

  return (
    <>
      {loading && <LoadingPage />}

      {!loading && (
        <>
          {!formData.id && <Alert severity="info">Select a consolidator to change its data.</Alert>}

          {!!formData.id && (
            <Box component="form" onSubmit={onSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
              <FormControlLabel
                sx={{ ml: -1, mb: 2, float: 'right' }}
                control={<Switch name="status" checked={formData.status === 'active'} onChange={onStatusChange} />}
                label={ucfirst(formData.status)}
              />

              <Grid container columns={2}>
                <Grid item xs={2} lg={1}>
                  <Card variant="island">
                    <CardHeader title="Contact data" />

                    <CardContent>
                      <Grid container rowSpacing={2} columnSpacing={1} my={1}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="name"
                            label="Name"
                            value={formData.name}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="company"
                            label="Company"
                            value={formData.company}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            name="address"
                            label="Street"
                            value={formData.address}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            name="address_no"
                            label="No."
                            value={formData.address_no}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            name="postcode"
                            label="Postcode"
                            value={formData.postcode}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            name="city"
                            label="City"
                            value={formData.city}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="country"
                            label="Country"
                            value={formData.country}
                            onChange={onInputChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader title="Invoice document" />

                    <CardContent>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="locale-label">Locale</InputLabel>

                        <Select
                          labelId="locale-label"
                          name="locale"
                          label="Locale"
                          value={formData.locale}
                          onChange={onSelectChange}>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {user.supportedLocales.map((locale) => (
                            <MenuItem key={locale} value={locale}>
                              {locale}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Divider />

                      <Typography margin="normal" fontWeight="bold">
                        Footer
                      </Typography>

                      <TextField
                        fullWidth
                        margin="normal"
                        multiline
                        rows={6}
                        name="footer_left"
                        label="Left"
                        value={formData.footer_left}
                        onChange={onInputChange}
                      />

                      <TextField
                        fullWidth
                        margin="normal"
                        multiline
                        rows={6}
                        name="footer_center"
                        label="Center"
                        value={formData.footer_center}
                        onChange={onInputChange}
                      />

                      <TextField
                        fullWidth
                        margin="normal"
                        multiline
                        rows={6}
                        name="footer_right"
                        label="Right"
                        value={formData.footer_right}
                        onChange={onInputChange}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={2} lg={1}>
                  <Card variant="island">
                    <CardHeader title="Surcharges" />

                    <CardContent>
                      <Grid container columns={2} rowSpacing={2} columnSpacing={1} my={1}>
                        <Grid item xs={1}>
                          <SimpleNumberField
                            fullWidth
                            name="surcharge_zmc"
                            label="ZMC"
                            value={formData.surcharge_zmc}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={1}>
                          <SimpleNumberField
                            fullWidth
                            name="surcharge_emdn"
                            label="EMDN"
                            value={formData.surcharge_emdn}
                            onChange={onInputChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader title="Fulfilment" />

                    <CardContent>
                      <Grid container rowSpacing={2} columnSpacing={1} my={1}>
                        <Grid item xs={6}>
                          <SimpleNumberField
                            fullWidth
                            name="iata_number"
                            label="IATA Number"
                            value={formData.iata_number}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6} />

                        <Grid item xs={6}>
                          <TextField fullWidth name="agt" label="AGT" value={formData.agt} onChange={onInputChange} />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField fullWidth name="bst" label="BST" value={formData.bst} onChange={onInputChange} />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={6}>
                          <SimpleNumberField
                            fullWidth
                            name="original_ticketing_location"
                            label="Original TKTLOC"
                            value={formData.original_ticketing_location}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <SimpleNumberField
                            fullWidth
                            name="ticketing_location"
                            label="TKTLOC"
                            value={formData.ticketing_location}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <SimpleNumberField
                            fullWidth
                            name="original_invoicing_location"
                            label="Original INVLOC"
                            value={formData.original_invoicing_location}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <SimpleNumberField
                            fullWidth
                            name="invoicing_location"
                            label="INVLOC"
                            value={formData.invoicing_location}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            name="form_of_payment"
                            label="Form of payment (FOP)"
                            value={formData.form_of_payment}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            name="currency_code"
                            label="Currency"
                            value={formData.currency_code}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            name="point_of_sale"
                            label="Point of Sale"
                            value={formData.point_of_sale}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            name="psp_id"
                            label="PSPID"
                            value={formData.psp_id}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={4}>
                          <SimpleNumberField
                            fullWidth
                            name="vat_rate_national"
                            label="National"
                            value={formData.vat_rate_national}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <SimpleNumberField
                            fullWidth
                            name="vat_rate_continental"
                            label="Continental"
                            value={formData.vat_rate_continental}
                            onChange={onInputChange}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <SimpleNumberField
                            fullWidth
                            name="vat_rate_international"
                            label="International"
                            value={formData.vat_rate_international}
                            onChange={onInputChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <HistoryCard
                    created_by={formData.created_by}
                    created_at={formData.created_at}
                    updated_by={formData.updated_by}
                    updated_at={formData.updated_at}
                  />
                </Grid>
              </Grid>

              <Stack direction="row" ml={1}>
                <LoadingButton text="Save" sendingData={sending} />

                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => updateLoadedFormData(loadedFormData)}
                  sx={{ ml: 2 }}>
                  Reset
                </Button>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
}
