import { AxiosResponse } from 'axios';
import { format, parseJSON } from 'date-fns';

import { DateFormatFull, DateFormatInput, UserRoleAdmin, UserRoleOperator, UserRoleUser } from '../constants/utils';
import DashboardItem from '../models/dashboard/DashboardItem';
import DashboardChart from '../models/dashboard/DashboardChart';

export function save_file(response: AxiosResponse) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const headerLine = response.headers['content-disposition'];
  const filename = headerLine.substring(headerLine.indexOf('"') + 1, headerLine.lastIndexOf('"'));
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function check_user_access(requiredRole: string, userRole: string): boolean {
  return (
    userRole === UserRoleAdmin ||
    (requiredRole === UserRoleOperator && userRole === UserRoleOperator) ||
    requiredRole === UserRoleUser
  );
}

export function convert_date_full_from_api_full(date: string) {
  return format(parseJSON(date), DateFormatFull);
}

export function convert_date_from_api_full(date: string) {
  return format(parseJSON(date), DateFormatInput);
}

export function convert_to_dashboard_array(views: any, sales: any): DashboardItem[] {
  let totals: DashboardChart = {};

  let dat: string;
  let brand: string;

  for (dat in views.dates) {
    if (!totals.hasOwnProperty(views.dates[dat])) {
      totals[views.dates[dat]] = {
        date: views.dates[dat],
        views: 0,
        sales: 0,
      };
    }
  }

  for (brand in views.brands) {
    for (dat in views.brands[brand].data) {
      if (totals.hasOwnProperty(dat)) {
        totals[dat].views += views.brands[brand].data[dat];
      } else {
        totals[dat].views = views.brands[brand].data[dat];
      }
    }
  }

  for (brand in sales.brands) {
    for (dat in sales.brands[brand].data) {
      if (totals.hasOwnProperty(dat)) {
        totals[dat].sales += sales.brands[brand].data[dat];
      } else {
        totals[dat].sales = sales.brands[brand].data[dat];
      }
    }
  }

  return Object.values(totals);
}
