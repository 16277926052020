import { useEffect } from 'react';

import useSecondaryMenu from './useSecondaryMenu';

import TravelAgency from '../models/user/TravelAgency';

import { useAuth } from './useAuth';

export default function useCurrentBrands(type: 'single' | 'multiple') {
  const { user } = useAuth();
  const { selectedItems, setType, setSelectedItems, setItems } = useSecondaryMenu();

  useEffect(() => {
    setType(type);
    setSelectedItems([]);

    setItems(
      user.travelAgencies.map((travelAgency) => {
        return {
          name: travelAgency.name,
          value: travelAgency.name,
        };
      })
    );
  }, [type, user.travelAgencies, setType, setItems, setSelectedItems]);

  return selectedItems.map((item) =>
    user.travelAgencies.find((brand) => item.value === brand.name)
  ) as TravelAgency[];
}
