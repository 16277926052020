import { useNavigate } from 'react-router-dom';

import { AccountCircleRoundedIcon, Box, Breadcrumbs, Button, Typography, Link } from '../imports';

import Page from '../models/PageConfig/Page';

import { UrlProfile } from '../constants/urls';

import { useAuth } from '../services/useAuth';
import usePageConfig from '../services/usePageConfig';
import useSecondaryMenu from '../services/useSecondaryMenu';

export default function Header() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const secondaryMenu = useSecondaryMenu();
  let { currentPage, setTitle, pagePath } = usePageConfig();

  document.title = 'Dashboard - ' + secondaryMenu.pageTitle;

  function getPagePath(page: Page) {
    let path = '';

    for (let item of pagePath) {
      path += '/' + item.path;

      if (item === page) {
        break;
      }
    }

    return path;
  }

  if (secondaryMenu.type === 'single' && secondaryMenu.selectedItems.length) {
    pagePath = [...pagePath, currentPage];
  }

  return (
    <Box width="100%" overflow="auto" mb={2}>
      <Box sx={{ float: 'left' }}>
        <Breadcrumbs separator="●">
          {pagePath.map((page, index) => (
            <Link
              key={index}
              component="button"
              underline="hover"
              fontSize="medium"
              color="grey"
              onClick={() => {
                setTitle('');
                secondaryMenu.setSelectedItems([]);
                navigate(getPagePath(page));
              }}>
              <Typography>{page.name}</Typography>
            </Link>
          ))}
          <Typography color="black">{secondaryMenu.pageTitle}</Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ float: 'right' }}>
        <Button onClick={() => navigate(UrlProfile)} variant="text" color="inherit">
          <Typography fontWeight="bold" fontSize="large" mr={1}>
            {user.name}
          </Typography>
          <AccountCircleRoundedIcon fontSize="large" />
        </Button>
      </Box>
    </Box>
  );
}
