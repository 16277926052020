import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, DeleteIcon, EditIcon, Switch, useTheme } from '../imports';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DialogDelete from './DialogDelete';

import ListData from '../models/ListData';

interface DataGridListProps {
  filter: string;
  columns: GridColDef[];
  data: ListData[];
  itemName: string;
  urlPrefix: string;
  onDelete: any;
  onStatusChange: (itemId: number, newStatus: string) => void;
}

const iconStyle = {
  color: 'gray',
  cursor: 'pointer',
  marginX: 0.5,
};

export default function DataGridList(props: DataGridListProps) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState(0);
  const [deleteDialogName, setDeleteDialogName] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();

  const columns = [...props.columns];

  const onDelete = (itemId: number) => {
    const item = props.data.find((item) => item.id === itemId);

    if (item) {
      setDeleteDialogOpen(true);
      setDeleteDialogId(item.id);
      setDeleteDialogName(item.name);
    }
  };

  const dialogCallback = (result: boolean) => {
    setDeleteDialogOpen(false);

    if (result) {
      props.onDelete(deleteDialogId);
    }
  };

  const onStatusChange = (itemId: number, checked: boolean) => {
    props.onStatusChange(itemId, checked ? 'active' : 'inactive');
  };

  columns.unshift({
    field: 'status',
    headerName: '',
    renderCell: (params) => {
      return (
        <Switch
          name="status"
          checked={params.value === 'active'}
          onChange={(_, checked) => onStatusChange(parseInt('' + params.id), checked)}
        />
      );
    },
    flex: 0.2,
  });

  columns.push({
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: (params) => {
      return (
        <Container sx={{ textAlign: 'center' }}>
          <EditIcon sx={iconStyle} onClick={() => navigate(props.urlPrefix + '/' + params.id)} />
          <DeleteIcon sx={iconStyle} onClick={() => onDelete(parseInt('' + params.id))} />
        </Container>
      );
    },
    flex: 0.5,
  });

  columns.forEach((column) => column.flex || (column.flex = 1));

  return (
    <>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        columns={columns}
        rows={props.data}
        pageSize={25}
        disableColumnMenu
        initialState={{
          sorting: {
            sortModel: [{ field: 'updated_at', sort: 'desc' }],
          },
        }}
        getRowClassName={(params) => (params.row.status === 'inactive' ? 'disabled' : '')}
        sx={{
          border: 0,
          mt: 2,
          '& .MuiDataGrid-main': {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: '#ffffff',
            boxShadow: theme.shadows[3],
            paddingX: theme.spacing(2),
          },
          '& .MuiDataGrid-columnHeaders': { marginX: theme.spacing(2) },
          '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
          '& .MuiDataGrid-columnSeparator': { display: 'none' },
          '& .MuiDataGrid-footerContainer': { border: 0 },
          '& .disabled': { color: '#C1C1C1' },
        }}
      />

      <DialogDelete
        open={deleteDialogOpen}
        title={'Delete ' + props.itemName}
        text={'Do you really want to delete ' + props.itemName + ' "' + deleteDialogName + '"'}
        agreeText={'DELETE ' + props.itemName.toUpperCase()}
        dialogCallback={dialogCallback}
      />
    </>
  );
}
