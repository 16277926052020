import { useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

import {
  Alert,
  Box,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '../../imports';

import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import LoadingButton from '../../components/LoadingButton';

import { SalesData } from '../../models/report/SalesData';

import { ApiReportSalesDownload, ApiReportSalesGet } from '../../constants/endpoints';
import { DateFormatApi, DateFormatInput } from '../../constants/utils';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import usePageConfig from '../../services/usePageConfig';
import useAbortSignal from '../../services/useAbortSignal';
import useCurrentBrands from '../../services/useCurrentBrands';
import { save_file } from '../../utils/utils';

const columns: GridColDef[] = [
  { field: 'identifier', headerName: 'Identifier' },
  { field: 'filekey', headerName: 'Filekey' },
  { field: 'pnrCreationDate', headerName: 'Booking date' },
  { field: 'paymentCreationDate', headerName: 'Purchase date (UTC)' },
  { field: 'paymentCreationTime', headerName: 'Purchase time (UTC)' },
  { field: 'crs', headerName: 'GDS' },
  { field: 'airlines', headerName: 'Airline(s)' },
  { field: 'items', headerName: 'Items' },
  {
    field: 'total',
    headerName: 'Total',
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => params.value + ' €',
  },
  {
    field: 'provision',
    headerName: 'Provision',
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => params.value + ' €',
  },
];

columns.map((column) => (column.flex = 1));

export default function Purchases() {
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date());
  const [dateTo, setDateTo] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SalesData[]>([]);
  const [downloading, setDownloading] = useState(false);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { currentPage } = usePageConfig();
  const currentBrands = useCurrentBrands('multiple');
  const abortSignal = useAbortSignal();

  const handleGenerate = () => {
    setLoading(true);

    axios
      .post(
        ApiReportSalesGet,
        {
          brands: currentBrands.map((travelAgency) => travelAgency.name),
          from: format(dateFrom as Date, DateFormatApi),
          to: format(dateTo as Date, DateFormatApi),
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      )
      .then((data) => {
        setData(data.data.data);

        !data.data.data.length && setDataSendStatus({ open: true, success: false, message: 'No data found!' });
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  };

  const handleDownload = () => {
    setDownloading(true);

    axios
      .get(
        ApiReportSalesDownload +
          '/' +
          window.btoa(format(dateFrom as Date, DateFormatApi) + ',' + format(dateTo as Date, DateFormatApi)) +
          '/' +
          window.btoa(currentBrands.map((item) => item.name).join(',')),
        { responseType: 'blob', signal: abortSignal }
      )
      .then((response) => {
        setDownloading(false);

        save_file(response);
      });
  };

  return (
    <Paper variant="island" sx={{ position: 'relative' }}>
      <Typography variant="h5" marginBottom={2}>
        {currentPage.name}
      </Typography>

      {currentBrands && !currentBrands.length && (
        <Alert severity="info">You must select at least one travel agency and pick the desire dates.</Alert>
      )}

      <Box marginTop={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Date from"
              value={dateFrom}
              inputFormat={DateFormatInput}
              maxDate={dateTo}
              onChange={(newValue) => setDateFrom(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <DatePicker
              label="Date to"
              value={dateTo}
              inputFormat={DateFormatInput}
              minDate={dateFrom}
              onChange={(newValue) => setDateTo(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <LoadingButton
              text="GENERATE REPORT"
              disabled={!currentBrands.length}
              sendingData={loading}
              onClick={handleGenerate}
            />
          </Stack>
        </LocalizationProvider>
      </Box>

      {data && data.length > 0 && (
        <>
          <Table
            sx={{
              position: 'absolute',
              width: 'auto',
              top: 20,
              right: 20,
              border: 'solid 2px #7fd4b1',
              backgroundColor: '#d3efe2',
              '.MuiTableCell-root': { border: 0, paddingY: 1, paddingX: 2 },
            }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Total sales:</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {data
                    .map((row) => parseFloat(row.total))
                    .reduce((prev, current) => prev + current)
                    .toFixed(2)}
                  &nbsp;€
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Provision:</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {data
                    .map((row) => parseFloat(row.provision))
                    .reduce((prev, current) => prev + current)
                    .toFixed(2)}
                  &nbsp;€
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Divider sx={{ my: 2 }} />

          <DataGrid
            autoHeight
            disableSelectionOnClick
            columns={columns}
            rows={data}
            sx={{ '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' } }}
          />

          <LoadingButton
            size="large"
            variant="text"
            sx={{ my: 2, boxShadow: 2 }}
            text="DOWNLOAD REPORT"
            sendingData={downloading}
            onClick={handleDownload}
          />

          <Typography fontWeight="bold">Item details legend</Typography>

          <Stack direction="row" spacing={2}>
            <Typography>NCS: Non chargeable seat</Typography>
            <Typography>CS: Chargeable seat</Typography>
            <Typography>NCB: Non chargeable baggage</Typography>
            <Typography>CB: Chargeable baggage</Typography>
            <Typography>ACI: Automatic check-in</Typography>
            <Typography>BP: Baggage protection</Typography>
            <Typography>TF: Transfer</Typography>
            <Typography>CU: Cash Upgrade</Typography>
            <Typography>EX: Extra</Typography>
          </Stack>
        </>
      )}
    </Paper>
  );
}
