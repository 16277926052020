import { FormEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { parse } from 'date-fns';

import { Alert, Button, Card, CardContent, CardHeader, Container, Grid, Paper } from '../../imports';

import LoadingButton from '../../components/LoadingButton';
import LoadingPage from '../../components/LoadingPage';
import PreferencesBadge from './components/PreferencesBadge';
import PreferencesMessage from './components/PreferencesMessage';

import FunctionalitiesData from '../../models/functionalities/FunctionalitiesData';
import FunctionalitiesGetResponse from '../../models/functionalities/FunctionalitiesGetResponse';
import PreferencesGetResponse from '../../models/preferences/PreferencesGetResponse';
import PreferencesItemData from '../../models/preferences/PreferencesItemData';
import PreferencesItemInfo from '../../models/preferences/PreferencesItemInfo';
import PreferencesSetRequestItem from '../../models/preferences/PreferencesSetRequestItem';
import TravelAgency from '../../models/user/TravelAgency';

import { ApiFunctionalitiesGet, ApiPreferencesGet, ApiPreferencesSet } from '../../constants/endpoints';
import { DateFormatApi } from '../../constants/utils';
import { DataSendStatusInit } from '../../constants/DataSendStatus/DataSendStatusInit';
import { FunctionalitiesDataInit } from '../../constants/FunctionalitiesDataInit';
import { PreferencesItemInfoInit } from '../../constants/PreferencesItemInfoInit';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useAbortSignal from '../../services/useAbortSignal';
import useCurrentBrands from '../../services/useCurrentBrands';

type PreferencesData = PreferencesItemData[];

export default function Preferences() {
  const [functionalitiesData, setFunctionalitiesData] = useState<FunctionalitiesData>(FunctionalitiesDataInit);
  const [formFunctionalitiesData, setFormFunctionalitiesData] = useState<FunctionalitiesData>(FunctionalitiesDataInit);

  const [preferencesData, setPreferencesData] = useState<PreferencesData>([]);
  const [formPreferencesData, setFormPreferencesData] = useState<PreferencesData>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [sendingData, setSendingData] = useState<boolean>(false);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const currentBrands = useCurrentBrands('single');
  const abortSignal = useAbortSignal();

  const localeDefault: string = 'en_GB';
  const currentTravelAgency = currentBrands[0];
  const loadFormData = useCallback(() => {
    setLoading(true);

    Promise.all([
      axios.post<FunctionalitiesGetResponse>(
        ApiFunctionalitiesGet,
        {
          brand: (currentTravelAgency as TravelAgency).name,
        },
        {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        }
      ),
      axios.post<PreferencesGetResponse>(
        ApiPreferencesGet,
        {
          brand: (currentTravelAgency as TravelAgency).name,
        },
        {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        }
      ),
    ])
      .then((results) => {
        setFunctionalitiesData(results[0].data.data);
        setFormFunctionalitiesData(results[0].data.data);
        setPreferencesData(results[1].data.data);
        setFormPreferencesData(results[1].data.data);
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [currentTravelAgency, user.token, abortSignal, checkResponseError]);

  const validateDates = (item: PreferencesItemData) => {
    let fromDate: Date | null = null;
    let toDate: Date | null = null;

    if (item.from) {
      fromDate = parse(item.from as string, DateFormatApi, new Date());
    }

    if (item.to) {
      toDate = parse(item.to as string, DateFormatApi, new Date());
    }

    if (fromDate && toDate && fromDate > toDate) {
      throw new Error('The date from must not be later than the date to');
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // reset before request
    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    try {
      formPreferencesData.forEach(validateDates);

      const preferencesSetRequestItem: PreferencesSetRequestItem = {
        brand: (currentTravelAgency as TravelAgency).name,
        functionalities: {
          hintBarErrorEnabled: formFunctionalitiesData.hintBarErrorEnabled ?? false,
          hintUpsellEnabled: formFunctionalitiesData.hintUpsellEnabled ?? false,
        },
        preferences: formPreferencesData,
      };

      axios
        .post<PreferencesSetRequestItem[]>(ApiPreferencesSet, [preferencesSetRequestItem], {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        })
        .then(() => {
          setFunctionalitiesData(formFunctionalitiesData);
          setPreferencesData(formPreferencesData);
          setDataSendStatus({
            open: true,
            success: true,
            message: 'Preferences updated successfully.',
          });
        })
        .catch(checkResponseError)
        .finally(() => setSendingData(false));
    } catch (e) {
      setSendingData(false);
      setDataSendStatus({ open: true, success: false, message: 'Unable to update preferences.' });
    }
  };

  const handleReset = (): void => {
    setFormFunctionalitiesData(functionalitiesData);
    setFormPreferencesData(preferencesData);
  };

  const handleChangePreferencesData = (item: PreferencesItemData): void => {
    setFormPreferencesData((prevState) => prevState.filter((i: PreferencesItemData) => i.id !== item.id).concat(item));
  };

  const handleChangeFunctionalitiesData = (
    fields: Partial<Pick<FunctionalitiesData, 'hintBarErrorEnabled' | 'hintUpsellEnabled'>>
  ): void => {
    setFormFunctionalitiesData((prevState) => ({ ...prevState, ...fields }));
  };

  useEffect(() => {
    if (currentTravelAgency) {
      loadFormData();
    }
  }, [currentTravelAgency, loadFormData]);

  return (
    <Container maxWidth="lg" component="form" onSubmit={handleSubmit}>
      {!currentBrands.length ? (
        <Alert severity="info">Select a brand to change preferences.</Alert>
      ) : (
        <>
          {loading && <LoadingPage />}
          {!loading && (
            <>
              <Grid container columns={2}>
                <Grid item lg={1} xs={2}>
                  <Card variant="island">
                    <CardHeader title="Badges" />
                    <CardContent>
                      {PreferencesItemInfoInit.map((preferencesItemInfo: PreferencesItemInfo) => (
                        <PreferencesBadge
                          key={preferencesItemInfo.name}
                          name={preferencesItemInfo.name}
                          label={preferencesItemInfo.label}
                          badge={
                            formPreferencesData.find(
                              (item: PreferencesItemData) =>
                                item.type === 'badge' && item.name === preferencesItemInfo.name
                            ) as PreferencesItemData
                          }
                          onChange={handleChangePreferencesData}
                        />
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={1} xs={2}>
                  <Card variant="island">
                    <CardHeader title="Messages" />
                    <CardContent>
                      <PreferencesMessage
                        key="hintBarError"
                        name="hintBarError"
                        label="Hint bar warning message"
                        locale={localeDefault}
                        locales={user.supportedLocales}
                        messages={formPreferencesData.filter(
                          (item: PreferencesItemData) => item.type === 'message' && item.name === 'hintBarError'
                        )}
                        enabled={formFunctionalitiesData.hintBarErrorEnabled}
                        onChangeFunctionalities={handleChangeFunctionalitiesData}
                        onChangePreferences={handleChangePreferencesData}
                      />
                      <PreferencesMessage
                        key="hintUpsell"
                        name="hintUpsell"
                        label="Hint upsell message"
                        locale={localeDefault}
                        locales={user.supportedLocales}
                        messages={formPreferencesData.filter(
                          (item: PreferencesItemData) => item.type === 'message' && item.name === 'hintUpsell'
                        )}
                        enabled={formFunctionalitiesData.hintUpsellEnabled}
                        onChangeFunctionalities={handleChangeFunctionalitiesData}
                        onChangePreferences={handleChangePreferencesData}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Paper variant="action">
                <LoadingButton type="submit" text="Save" sendingData={sendingData} />
                <Button color="primary" variant="outlined" onClick={handleReset}>
                  Reset
                </Button>
              </Paper>
            </>
          )}
        </>
      )}
    </Container>
  );
}
