import { MarkupsData } from '../models/markups/MarkupsData';

export const MarkupsDataInit: MarkupsData = {
    id: null,
    chargeableSeat: null,
    nonChargeableSeat: null,
    chargeableExtras: null,
    chargeableCashUpgrade: null,
    nonChargeableBaggage: null,
    chargeableBaggage: null,
    automaticCheckin: null,
    insuranceBlueribbonbagsGold: null,
    insuranceBlueribbonbagsPlatinum: null,
    insuranceBlueribbonbagsDiamond: null,
    transfer: null,
};
