import React, { useId, useState } from 'react';

import { ArrowDropDownIcon, ArrowDropUpIcon, Button, Menu, MenuItem } from '../imports';

interface DropdownFieldProp {
  items: any[];
  value: string;
  disabled?: boolean;
  onSelect?: (newValue: string, oldValue: string) => void;
}

export default function DropdownField(props: DropdownFieldProp) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string>(props.value);

  const idButton = useId();
  const idMenu = useId();
  const open = Boolean(anchorEl);

  const handleClick = (value: string) => {
    setAnchorEl(null);
    setValue(value);

    props.onSelect && props.onSelect(value, props.value);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id={idButton}
        aria-controls={open ? idMenu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={props.disabled}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={handleOpen}
      >
        {props.items.find((item) => item.value === value)?.label}
      </Button>
      <Menu
        id={idMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': idButton,
        }}>
        {props.items
          .filter((item: any) => !item.hidden && item.value !== value)
          .map((item: any) => (
            <MenuItem key={idMenu + '-' + item.value} onClick={() => handleClick(item.value)}>
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
