import { format } from 'date-fns';
import { DateFormatApiFull } from './utils';

import { UserEdit } from '../models/user/UserEdit';

export const UserEditInit: UserEdit = {
  id: 0,
  salutation: '',
  first_name: '',
  last_name: '',
  name: '',
  email: '',
  phone: '',
  status: 'inactive',
  role: 'user',
  created_at: format(new Date(), DateFormatApiFull),
  updated_at: format(new Date(), DateFormatApiFull),
  created_by: '',
  updated_by: '',
  brands: [],
};
