import { FormEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { Button, Container, FormControl, Grid, Paper, Typography } from '../../imports';

import LoadingButton from '../../components/LoadingButton';
import LoadingPage from '../../components/LoadingPage';
import NumberField from '../../components/NumberField';
import SelectBrand from '../../components/SelectBrand';

import { MarkupsData } from '../../models/markups/MarkupsData';
import TravelAgency from '../../models/user/TravelAgency';

import { ApiMarkupsGet, ApiMarkupsSet } from '../../constants/endpoints';
import { MarkupsDataInit } from '../../constants/MarkupsDataInit';
import { DataSendStatusInit } from '../../constants/DataSendStatus/DataSendStatusInit';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useCurrentBrands from '../../services/useCurrentBrands';
import useAbortSignal from '../../services/useAbortSignal';

export default function Markups() {
  const [formData, setFormData] = useState<MarkupsData>(MarkupsDataInit);
  const [loadedFormData, setLoadedFormData] = useState<MarkupsData>(MarkupsDataInit);
  const [loading, setLoading] = useState<boolean>(true);
  const [sendingData, setSendingData] = useState<boolean>(false);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const currentBrands = useCurrentBrands('single');
  const abortSignal = useAbortSignal();

  const currentTravelAgency = currentBrands[0];

  function handleChange(event: any, value: any) {
    setFormData({ ...formData, ...{ [event.target.name]: value } });
  }

  const validate = () => {
    const markupsValues = [
      formData.id,
      formData.chargeableSeat,
      formData.nonChargeableSeat,
      formData.chargeableExtras,
      formData.chargeableCashUpgrade,
      formData.nonChargeableBaggage,
      formData.chargeableBaggage,
      formData.automaticCheckin,
      formData.insuranceBlueribbonbagsGold,
      formData.insuranceBlueribbonbagsPlatinum,
      formData.insuranceBlueribbonbagsDiamond,
      formData.transfer,
    ];

    for (let markupsValue of markupsValues) {
      if (markupsValue && !isNaN(markupsValue) && (markupsValue < 0 || markupsValue > 99.99)) {
        throw new Error('Wrong values');
      }
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // reset before request
    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    try {
      validate();

      axios
        .post(
          ApiMarkupsSet,
          [
            {
              brand: (currentTravelAgency as TravelAgency).name,
              markups: formData,
            },
          ],
          { headers: { 'Auth-User': user.token }, signal: abortSignal }
        )
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'Markups modified successfully.' });
        })
        .catch(checkResponseError)
        .finally(() => setSendingData(false));
    } catch (e) {
      setSendingData(false);
      setDataSendStatus({
        open: true,
        success: false,
        message: e instanceof Error ? e.message : 'Error updating data',
      });
    }
  };

  const loadFormData = useCallback(() => {
    setLoading(true);

    axios
      .post(
        ApiMarkupsGet,
        {
          brand: (currentTravelAgency as TravelAgency).name,
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      )
      .then((response) => {
        setLoadedFormData(response.data.data);
        setFormData(response.data.data);
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [currentTravelAgency, user.token, abortSignal, checkResponseError]);

  useEffect(() => {
    if (currentTravelAgency) {
      loadFormData();
    }
  }, [currentTravelAgency, loadFormData]);

  return (
    <Container maxWidth="lg" component="form" onSubmit={handleSubmit}>
      {!currentTravelAgency ? (
        <SelectBrand message="Select a travel agency to change markups." />
      ) : (
        <>
          {loading && <LoadingPage />}
          {!loading && (
            <>
              <Grid container columns={2}>
                <Grid item lg={1} xs={2}>
                  <Paper variant="island">
                    <Typography variant="h6">Seats</Typography>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="chargeableSeat"
                        id="chargeableSeat"
                        label="Chargeable seats"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText="minimum value should be 0."
                        maxValueErrorText="Should not be greater than 99.99."
                        value={formData.chargeableSeat?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="nonChargeableSeat"
                        id="nonChargeableSeat"
                        label="Non-chargeable seats"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.nonChargeableSeat?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>

                  <Paper variant="island">
                    <Typography variant="h6">Baggage</Typography>

                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="chargeableBaggage"
                        id="chargeableBaggage"
                        label="Chargeable baggage"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.chargeableBaggage ? formData.chargeableBaggage.toString() : ''}
                        onChange={handleChange}
                      />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="nonChargeableBaggage"
                        id="nonChargeableBaggage"
                        label="Non-chargeable baggage"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.nonChargeableBaggage ? formData.nonChargeableBaggage.toString() : ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>

                  <Paper variant="island">
                    <Typography variant="h6">Cash upgrade</Typography>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="chargeableCashUpgrade"
                        id="chargeableCashUpgrade"
                        label="Chargeable cash upgrade"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.chargeableCashUpgrade ? formData.chargeableCashUpgrade.toString() : ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>
                  <Paper variant="island">
                    <Typography variant="h6">Extras</Typography>

                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="chargeableExtras"
                        id="chargeableExtras"
                        label="Chargeable extras"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.chargeableExtras ? formData.chargeableExtras.toString() : ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item lg={1} xs={2}>
                  <Paper variant="island">
                    <Typography variant="h6">Baggage protection</Typography>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="insuranceBlueribbonbagsGold"
                        id="insuranceBlueribbonbagsGold"
                        label="Baggage protection gold"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.insuranceBlueribbonbagsGold?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="insuranceBlueribbonbagsPlatinum"
                        id="insuranceBlueribbonbagsPlatinum"
                        label="Baggage protection platinum"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.insuranceBlueribbonbagsPlatinum?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="insuranceBlueribbonbagsDiamond"
                        id="insuranceBlueribbonbagsDiamond"
                        label="Baggage protection diamond"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.insuranceBlueribbonbagsDiamond?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>
                  <Paper variant="island">
                    <Typography variant="h6">Automatic Check-in</Typography>

                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="automaticCheckin"
                        id="automaticCheckin"
                        label="Automated check-in"
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText={'minimum value should be 0.'}
                        maxValueErrorText={'Should not be greater than 99.99.'}
                        value={formData.automaticCheckin?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>

                  <Paper variant="island">
                    <Typography variant="h6">Transfer</Typography>
                    <FormControl fullWidth margin="normal">
                      <NumberField
                        name="transfer"
                        id="transfer"
                        label="Transfer"
                        error={false}
                        onlyInteger={false}
                        minValue={0}
                        maxValue={99.99}
                        minValueErrorText="minimum value should be 0."
                        maxValueErrorText="Should not be greater than 99.99."
                        value={formData.transfer?.toString() ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
              <Paper variant="action">
                <LoadingButton text="Save" sendingData={sendingData} />
                <Button color="primary" variant="outlined" onClick={() => setFormData(loadedFormData)}>
                  Reset
                </Button>
              </Paper>
            </>
          )}
        </>
      )}
    </Container>
  );
}
