import { Children, ReactNode } from 'react';

import { styled, SxProps } from '../imports';

type SimpleListProp = {
  children?: ReactNode;
  sx?: SxProps;
};

export default function SimpleList({ children, ...props }: SimpleListProp) {
  const List = styled('ul')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  }));

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
    padding: theme.spacing(0),
  }));

  return (
    <List {...props}>
      {Children.map(children, (child) => (
        <ListItem>{child}</ListItem>
      ))}
    </List>
  );
}
