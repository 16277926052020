import { useState } from 'react';
import { format } from 'date-fns';

import {
  AdapterDateFns,
  Box,
  DatePicker,
  DateValidationError,
  FormControl,
  Grid,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '../../../imports';

import { DateFormatApi, DateFormatInput } from '../../../constants/utils';
import PreferencesItemData from '../../../models/preferences/PreferencesItemData';

interface PreferencesBadgeProp {
  name: string;
  label: string;
  badge: PreferencesItemData;
  onChange: (badge: PreferencesItemData) => void;
}

export default function PreferencesBadge({ name, label, badge, onChange }: PreferencesBadgeProp) {
  const fromValue: Date | null = badge.from ? new Date(badge.from) : null;
  const [fromError, setFromError] = useState<boolean>(false);
  const [fromErrorText, setFromErrorText] = useState<string | null>(null);

  const toValue: Date | null = badge.to ? new Date(badge.to) : null;
  const [toError, setToError] = useState<boolean>(false);
  const [toErrorText, setToErrorText] = useState<string | null>(null);

  const handleChangeDate = (name: string, value: Date | null) => {
    let dateValue: string | null = null;

    if (value) {
      try {
        dateValue = format(value, DateFormatApi);
      } catch (e) {
        dateValue = null;
      }
    }

    onChange({ ...badge, [name]: dateValue });
  };
  const handleChangeValue = (event: any) => onChange({ ...badge, [event.target.name]: event.target.value });
  const handleErrorFrom = (reason: DateValidationError) => {
    switch (reason) {
      case 'invalidDate':
        setFromError(true);
        setFromErrorText('Use a valid date format');
        break;
      case 'maxDate':
        if (toValue instanceof Date) {
          setToError(true);
          setToErrorText('Choose a date on or after ' + format(toValue as Date, DateFormatInput));
        }
        break;
      default:
        setFromError(false);
        setFromErrorText(null);
        break;
    }
  };
  const handleErrorTo = (reason: DateValidationError) => {
    switch (reason) {
      case 'invalidDate':
        setToError(true);
        setToErrorText('Use a valid date format');
        break;
      case 'minDate':
        if (fromValue instanceof Date) {
          setToError(true);
          setToErrorText('Choose a date on or before ' + format(fromValue as Date, DateFormatInput));
        }
        break;
      default:
        setToError(false);
        setToErrorText(null);
        break;
    }
  };
  const handleResetFrom = (event: any): void => {
    event.preventDefault();

    if (event.key === 'Backspace' || event.key === 'Delete') {
      handleChangeDate('from', null);
    }
  };
  const handleResetTo = (event: any): void => {
    event.preventDefault();

    if (event.key === 'Backspace' || event.key === 'Delete') {
      handleChangeDate('to', null);
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl fullWidth={true}>
            <InputLabel id={'badges-' + name + '-label'}>Badge</InputLabel>
            <Select
              labelId={'badges-' + name + '-label'}
              name="value"
              label="Badge"
              value={badge.value ?? 'NONE'}
              onChange={handleChangeValue}>
              <MenuItem value="BETA">BETA</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="RENEWED">RENEWED</MenuItem>
              <MenuItem value="NONE">No Badge</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date from"
              value={fromValue}
              onChange={(value) => handleChangeDate('from', value)}
              onError={handleErrorFrom}
              maxDate={toValue}
              inputFormat={DateFormatInput}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  name="from"
                  error={fromError}
                  helperText={fromError ? fromErrorText ?? undefined : undefined}
                  fullWidth={true}
                  onKeyDown={handleResetFrom}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date to"
              value={toValue}
              onChange={(value) => handleChangeDate('to', value)}
              onError={handleErrorTo}
              minDate={fromValue}
              inputFormat={DateFormatInput}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  name="to"
                  error={toError}
                  helperText={toError ? toErrorText ?? undefined : undefined}
                  fullWidth={true}
                  onKeyDown={handleResetTo}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
}
