import { Box, Card, CardContent, CardHeader, Typography } from '../imports';

import { convert_date_full_from_api_full } from '../utils/utils';

interface HistoryCardProps {
  created_by: string;
  created_at: string;

  updated_by: string;
  updated_at: string;
}

export default function HistoryCard(props: HistoryCardProps) {
  return (
    <Card variant="history">
      <CardHeader title="History" />
      <CardContent>
        <Box>
          <Typography display="inline" sx={{ fontWeight: 'bold' }}>
            Created by{' '}
          </Typography>
          <Typography display="inline">{props.created_by} ● </Typography>
          <Typography display="inline">
            {props.created_at ? convert_date_full_from_api_full(props.created_at) : ''}
          </Typography>
        </Box>
        <Box>
          <Typography display="inline" sx={{ fontWeight: 'bold' }}>
            Last updated by{' '}
          </Typography>
          <Typography display="inline">{props.updated_by} ● </Typography>
          <Typography display="inline">
            {props.updated_at ? convert_date_full_from_api_full(props.updated_at) : ''}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
