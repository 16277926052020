import {
  AirplaneTicketIcon,
  EditRoadIcon,
  FeedIcon,
  PaymentsIcon,
  PeopleIcon,
  ShareIcon,
  ShoppingCartIcon,
  SpeedIcon,
  TuneIcon,
} from '../../imports';

import NotFound from '../../pages/NotFound';
import Dashboard from '../../pages/Dashboard';
import Consolidators from '../../pages/Consolidators'
import Brand from '../../pages/Brand';
import Functionalities from '../../pages/Functionalities';
import Markups from '../../pages/Markups';
import Preferences from '../../pages/Preferences';
import Views from '../../pages/Views';
import Purchases from '../../pages/Purchases';
import UsersList from '../../pages/Users/UsersList';
import UsersEdit from '../../pages/Users/UsersEdit';
import Payments from '../../pages/Payments';
import Profile from '../../pages/Profile';

import PageConfig from '../../models/PageConfig/PageConfig';

import {
  UrlBrand,
  UrlConsolidators,
  UrlDashboard,
  UrlFunctionalities,
  UrlMarkups,
  UrlPreferences,
  UrlViews,
  UrlPurchases,
  UrlPayments,
  UrlUsers,
  UrlProfile,
} from '../urls';

import { UserRoleUser, UserRoleAdmin, UserRoleOperator } from '../utils';

let config: PageConfig = {
  error: {
    name: 'Not Found',
    path: '*',
    element: <NotFound />,
    role: UserRoleUser,
  },
  dashboard: {
    name: 'Dashboard',
    path: UrlDashboard,
    icon: <SpeedIcon />,
    element: <Dashboard />,
    role: UserRoleUser,
  },
  consolidators: {
    name: 'Consolidators',
    path: UrlConsolidators,
    icon: <ShareIcon />,
    element: <Consolidators />,
    role: UserRoleAdmin,
  },
  brand: {
    name: 'Brand',
    path: UrlBrand,
    icon: <EditRoadIcon />,
    element: <Brand />,
    role: UserRoleUser,
  },
  functionalities: {
    name: 'Functionalities',
    path: UrlFunctionalities,
    icon: <TuneIcon sx={{ transform: 'rotate(90deg)' }} />,
    element: <Functionalities />,
    role: UserRoleUser,
  },
  markups: {
    name: 'Markups',
    path: UrlMarkups,
    icon: <PaymentsIcon />,
    element: <Markups />,
    role: UserRoleUser,
  },
  badges: {
    name: 'Badges & messages',
    path: UrlPreferences,
    icon: <AirplaneTicketIcon />,
    element: <Preferences />,
    role: UserRoleUser,
  },
  bookings: {
    name: 'Views',
    path: UrlViews,
    icon: <FeedIcon />,
    element: <Views />,
    role: UserRoleUser,
  },
  purchases: {
    name: 'Purchases',
    path: UrlPurchases,
    icon: <ShoppingCartIcon />,
    element: <Purchases />,
    role: UserRoleUser,
  },
  payments: {
    name: 'Payments',
    path: UrlPayments,
    icon: <PaymentsIcon />,
    element: <Payments />,
    role: UserRoleOperator,
  },
  users: {
    name: 'Users',
    path: UrlUsers,
    icon: <PeopleIcon />,
    element: <UsersList />,
    role: UserRoleAdmin,
    children: {
      edit: {
        name: 'User edit',
        path: ':userId',
        element: <UsersEdit />,
        role: UserRoleAdmin,
      },
    },
  },
  profile: {
    name: 'Profile',
    path: UrlProfile,
    icon: <PeopleIcon />,
    element: <Profile />,
    role: UserRoleUser,
  },
};

Object.keys(config).forEach(
  (key) => (config[key].path = config[key].path.startsWith('/') ? config[key].path.substring(1) : config[key].path)
);

export const pageConfig = config;
