import FunctionalitiesData from '../models/functionalities/FunctionalitiesData';
import { TravelAgencyNumberFunctionalitiesInit } from './TravelAgencyNumberFunctionalitiesInit';

export const FunctionalitiesDataInit: FunctionalitiesData = {
  id: null,
  travelAgencyId: null,
  shoppingEnabled: false,
  seatDisplayEnabled: false,
  seatDisplayCrsList: [],
  seatReservationNonChargeableEnabled: false,
  seatReservationNonChargeableCrsList: [],
  seatReservationChargeableEnabled: false,
  seatReservationChargeableCrsList: [],
  extrasEnabled: false,
  extrasCrsList: [],
  cashUpgradeEnabled: false,
  cashUpgradeCrsList: [],
  baggageEnabled: false,
  baggageCrsList: [],
  mealEnabled: false,
  mealCrsList: [],
  automaticCheckinAirobotEnabled: false,
  automaticCheckinAirobotMinDays: null,
  automaticCheckinAirobotMaxDays: null,
  automaticCheckinAirobotLimit: null,
  insuranceSetooBannerEnabled: false,
  insuranceBlueribbonbagsEnabled: false,
  transferDistribusionEnabled: false,
  pdfTravelplanCreateEnabled: false,
  frequentFlyerNumberEnabled: false,
  profileEnabled: false,
  travelAgencyNumbers: [],
  travelAgencyNumberFunctionalities: TravelAgencyNumberFunctionalitiesInit,
  eTicketsDisplayEnabled: false,
  hintBarErrorEnabled: false,
  hintUpsellEnabled: false,
  covidTestEnabled: false,
  co2CompensationEnabled: false,
  invoiceDisplayEnabled: false,
  hotjarId: null,
  crsList: [],
  crsPriorityList: [],
  useDataFromKim: false,
};
