import { ChangeEvent } from 'react';

import { FormControlLabel, Switch } from '../../../imports';

import CheckboxField from '../../../components/CheckboxField';
import SimpleList from '../../../components/SimpleList';

import FunctionalitiesData from '../../../models/functionalities/FunctionalitiesData';

type CrsListData = Pick<
  FunctionalitiesData,
  | 'seatDisplayEnabled'
  | 'seatDisplayCrsList'
  | 'seatReservationNonChargeableEnabled'
  | 'seatReservationNonChargeableCrsList'
  | 'seatReservationChargeableEnabled'
  | 'seatReservationChargeableCrsList'
  | 'extrasEnabled'
  | 'extrasCrsList'
  | 'cashUpgradeEnabled'
  | 'cashUpgradeCrsList'
  | 'baggageEnabled'
  | 'baggageCrsList'
  | 'mealEnabled'
  | 'mealCrsList'
  // Items without CrsList
  | 'insuranceSetooBannerEnabled'
  | 'insuranceBlueribbonbagsEnabled'
  | 'transferDistribusionEnabled'
  | 'pdfTravelplanCreateEnabled'
  | 'frequentFlyerNumberEnabled'
  | 'profileEnabled'
  | 'eTicketsDisplayEnabled'
  | 'covidTestEnabled'
  | 'co2CompensationEnabled'
  | 'invoiceDisplayEnabled'
>;

type CrsListProp = {
  name: string;
  label: string;
  items?: string[];
  selected?: string[];
  enabled: boolean;
  updateFields: (fields: Partial<CrsListData>) => void;
};

export default function CrsList(props: CrsListProp) {
  const selected: string[] = props.selected || [];

  const replace = (crsItem: string): string => {
    return ['GALILEO', 'WORLDSPAN'].indexOf(crsItem) !== -1 ? 'TRAVELPORT' : crsItem;
  };

  const unique = (value: string, index: number, array: string[]): boolean => {
    return array.indexOf(value) === index;
  };

  const crsTransform: { [key: string]: string } = {
    'AMADEUS': 'AMA',
    'DIRECT CONNECT': 'DC',
    'FARELOGIX': 'FLX',
    'SABRE': 'SAB',
    'TRAVELPORT': 'TP',
  };

  const handleChange = (crsItem: string, checked: boolean): void => {
    const crsItems: string[] = crsItem === 'TRAVELPORT' ? ['GALILEO', 'WORLDSPAN'] : [crsItem];

    props.updateFields({
      [props.name + 'CrsList']: checked
        ? selected.concat(crsItems)
        : selected.filter((i: string) => crsItems.indexOf(i) === -1),
    });
  };

  const handleChecked = (crsItem: string): boolean => {
    return selected.map(replace).filter(unique).indexOf(crsItem) !== -1;
  };

  return (
    <SimpleList>
      <FormControlLabel
        sx={{ m: 0.1, ...(props.items && { width: 200 }) }}
        label={props.label}
        control={
          <Switch
            name={props.name + 'Enabled'}
            checked={props.enabled}
            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
              props.updateFields({ [event.target.name]: checked })
            }
          />
        }
      />

      {props.enabled &&
        props.items &&
        props.items
          .map(replace)
          .filter(unique)
          .map((item: string) => (
              <CheckboxField
                key={props.name + '-' + item}
                label={crsTransform[item] || item}
                checked={handleChecked(item)}
                onChange={(checked: boolean) => handleChange(item, checked)}
              />
            ))
      }
    </SimpleList>
  );
}
