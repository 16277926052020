import BrandProfileFormData from '../models/brandProfile/BrandProfileFormData';

export const BrandProfileFormDataInit: BrandProfileFormData = {
  name: '',
  company: '',
  street: '',
  number: '',
  postcode: '',
  city: '',
  country: '',
  telephone: '',
  telefax: '',
  emailB2c: '',
  codes: [],
  subDomain_emailFeedback: '',
  subDomain_emailPurchaseError: '',

  weblinks_Website: '',
  weblinks_ContactForm: '',
  weblinks_FAQs: '',
  weblinks_AppleApp: '',
  weblinks_AndroidApp: '',
  weblinks_Hotel: '',
  weblinks_Car: '',
  weblinks_Insurance: '',
  weblinks_Parking: '',
  weblinks_TravelCard: '',
  weblinks_SearchForm: '',
  weblinks_Flightright: '',
  weblinks_BannerLink: '',
  weblinks_BannerImageDesktop: '',
  weblinks_BannerImageMobile: '',
  weblinks_CovidTest: '',
  weblinks_Co2Compensation: '',

  functionalities_hotjarId: '',
  functionalities_useDataFromKim: false,
};
