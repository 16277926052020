import { CheckIcon, Chip, ClearIcon } from '../imports';

interface CheckboxFieldProp {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export default function CheckboxField(props: CheckboxFieldProp) {
  const checked = props.checked ?? false;

  const handleChange = (checked: boolean) => {
    props.onChange && props.onChange(checked);
  };

  return (
    <>
      <Chip
        label={props.label}
        color={checked ? 'primary' : 'secondary'}
        variant="transfer"
        deleteIcon={checked ? <ClearIcon /> : <CheckIcon />}
        disabled={props.disabled}
        onDelete={() => handleChange(!checked)}
      />
    </>
  );
}
