export const DateFormatInput = 'dd.MM.yyyy';
export const DateFormatFull = 'dd.MM.yyyy, HH:mm:ss';
export const DateFormatInputExtended = 'dd.MM.yyyy HH:mm:ss';
export const DateFormatApi = 'yyyy-MM-dd';
export const DateFormatApiFull = 'yyyy-MM-dd HH:mm:ss';
export const DateFormatApiExtended = 'dd-MMM-yy HH:mm:ss';

export const automaticCheckInMinValue = 1;
export const automaticCheckInMinValueError = 'Value must be greater than 1';
export const automaticCheckInMaxValue = 255;
export const automaticCheckInMaxValueError = 'Value must be less than 255';

export const leftMenuWidth = 300;

export const UserRoleAdmin = 'admin';
export const UserRoleOperator = 'operator';
export const UserRoleUser = 'user';

export const PasswordResetLinkExpires = 1;
