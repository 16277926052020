import { createTheme } from '../imports';
import { GlobalTheme } from './Global';

export const DefaultTheme = createTheme(
  {
    components: {
      MuiCard: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...((ownerState.variant === 'island' || ownerState.variant === 'history') && {
              boxShadow: theme.shadows[3],
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              padding: theme.spacing(0),
              '.MuiCardHeader-action': {
                display: 'contents',
              },
              '.MuiCardHeader-title': {
                fontSize: theme.typography.h6.fontSize,
              },
              '.MuiCardContent-root': {
                marginTop: 0,
                paddingTop: 0,
                '.MuiDivider-root': {
                  color: '#979797',
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                },
              },
              '.MuiCardContent-root:last-child': {
                paddingBottom: theme.spacing(1),
              },
            }),
            ...(ownerState.variant === 'history' && {
              boxShadow: theme.shadows[0],
              '.MuiCardHeader-title': {
                color: '#6D7174',
              },
              '.MuiCardContent-root .MuiTypography-root': {
                color: '#313C45',
                fontSize: '13px',
              },
              border: 'none',
              backgroundColor: 'transparent',
            }),
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            borderRadius: 12,
            ...(ownerState.variant === 'action' && {
              backgroundColor: 'transparent',
              boxShadow: theme.shadows[0],
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              padding: theme.spacing(0),
              '& .MuiButtonBase-root:nth-of-type(n+2)': {
                marginLeft: theme.spacing(2),
              },
            }),
            ...(ownerState.variant === 'island' && {
              boxShadow: theme.shadows[3],
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              padding: theme.spacing(2),
            }),
          }),
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          avatar: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'transfer' &&
              ownerState.color === 'primary' && {
                backgroundColor: '#FFFFFF',
                color: theme.palette.primary.main,
                fontSize: 'smaller',
                fontWeight: 'bold',
              }),
          }),
          deleteIcon: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'transfer' && {
              color: theme.palette.primary.main,
              '&:hover': { color: theme.palette.primary.dark },
            }),
          }),
          label: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'transfer' && {
              color: theme.palette.text.primary,
            }),
          }),
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'transfer' && {
              borderColor: '#B3B4B5',
              borderWidth: 1,
              borderStyle: 'solid',
            }),
            ...(ownerState.variant === 'transfer' &&
              ownerState.color === 'primary' && {
                backgroundColor: '#BFDAEF',
                '&:hover': { borderColor: theme.palette.text.secondary, boxShadow: theme.shadows[3] },
              }),
            ...(ownerState.variant === 'transfer' &&
              ownerState.color === 'secondary' && {
                backgroundColor: '#FFFFFF',
                '&:hover': { borderColor: theme.palette.text.secondary, boxShadow: theme.shadows[3]  },
              }),
          }),
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiSwitch: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            minWidth: 140,
            borderRadius: 25,
            textTransform: 'none',
            ...(ownerState.variant === 'outlined' && {
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.05) inset',
            }),
            ...(ownerState.variant === 'contained' && {
              boxShadow: theme.shadows[3],
            }),
          }),
        },
      },
    },
  },
  GlobalTheme
);
