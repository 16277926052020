import {
  Button,
  ClearIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '../imports';

interface DialogDeleteProps {
  open: boolean;
  title: string;
  text: string;
  agreeText: string;
  dialogCallback: any;
}

export default function DialogDelete(props: DialogDeleteProps) {
  const onAgree = () => {
    props.dialogCallback(true);
  };

  const onClose = () => {
    props.dialogCallback(false);
  };

  return (
    <Dialog open={props.open} onClose={onClose}>
      <DialogTitle>
        {props.title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button onClick={onAgree}>{props.agreeText}</Button>
      </DialogActions>
    </Dialog>
  );
}
