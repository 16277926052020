import PreferencesItemInfo from '../models/preferences/PreferencesItemInfo';

export const PreferencesItemInfoInit: PreferencesItemInfo[] = [
  { name: 'seatReservation', label: 'Seat Reservation' },
  { name: 'extras', label: 'Extras' },
  { name: 'baggage', label: 'Baggage' },
  { name: 'baggageProtection', label: 'Baggage Protection' },
  { name: 'meal', label: 'Meal' },
  { name: 'cashUpgrade', label: 'Cash upgrade' },
  { name: 'transfer', label: 'Transfer' },
];
