import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Button, Stack } from '../../../imports';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DataGridList from '../../../components/DataGridList';
import ListFilter, { filterData } from '../../../components/ListFilter';
import LoadingPage from '../../../components/LoadingPage';

import { UserEdit } from '../../../models/user/UserEdit';

import { ApiUsers, ApiUsersStatus } from '../../../constants/endpoints';
import { UrlUsers } from '../../../constants/urls';

import { convert_date_from_api_full, ucfirst } from '../../../utils/utils';
import { useAuth } from '../../../services/useAuth';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import useAbortSignal from '../../../services/useAbortSignal';

const columns: GridColDef[] = [
  { field: 'last_name', headerName: 'Last name' },
  { field: 'first_name', headerName: 'First name' },
  { field: 'email', headerName: 'Email' },
  {
    field: 'role',
    headerName: 'Role',
    renderCell: (params: GridRenderCellParams) => {
      return ucfirst(params.value);
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last update',
    renderCell: (params: GridRenderCellParams) => {
      return convert_date_from_api_full(params.value);
    },
  },
];

export default function UsersList() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserEdit[]>([]);
  const [loadedData, setLoadedData] = useState<UserEdit[]>([]);
  const [filter, setFilter] = useState('');

  const { user } = useAuth();
  const { checkResponseError } = useDataSendStatus();
  const navigate = useNavigate();
  const abortSignal = useAbortSignal();

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get(ApiUsers, { headers: { 'Auth-User': user.token }, signal: abortSignal })
      .then((data) => {
        setLoading(false);
        setLoadedData(data.data.data ?? []);
      })
      .catch(checkResponseError);
  }, [user.token, abortSignal, checkResponseError]);

  const onDelete = (deleteId: number) => {
    setLoading(true);

    axios
      .delete(ApiUsers + '/' + deleteId, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then(() => navigate(UrlUsers))
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  };

  const onStatusChange = (itemId: number, status: string) => {
    setLoading(true);

    axios
      .post(
        ApiUsersStatus + '/' + itemId,
        { status: status },
        {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        }
      )
      .then(() => navigate(UrlUsers))
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  };

  useEffect(loadData, [loadData]);

  useEffect(() => setData(filterData(loadedData, filter)), [filter, loadedData]);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <>
          <Stack direction="row-reverse">
            <Button
              size="medium"
              sx={{ position: 'relative', my: 2, marginLeft: 3 }}
              variant="contained"
              onClick={() => navigate(UrlUsers + '/0')}>
              NEW
            </Button>
            <ListFilter filterCallBack={setFilter} placeholder="" />
          </Stack>

          <DataGridList
            filter={filter}
            columns={columns}
            data={data}
            itemName="user"
            urlPrefix={UrlUsers}
            onDelete={onDelete}
            onStatusChange={onStatusChange}
          />
        </>
      )}
    </>
  );
}
