import { createRef, SyntheticEvent } from 'react';

import { AddIcon, Box, Button, Chip, FormControl, InputAdornment, InputLabel, OutlinedInput, styled } from '../imports';

import SimpleList from './SimpleList';

type NumberMultiInputProp = {
  id: string;
  name: string;
  label: string;
  value: string[];
  onChange?: (value: string[]) => void;
};

export default function NumberMultiInput(props: NumberMultiInputProp) {
  const inputNumberRef = createRef<HTMLInputElement>();

  const IconButton = styled(Button)(() => ({
    borderRadius: '50%',
    minWidth: 0,
    padding: 0,
  }));

  const handleDelete = (deleteValue: string) => {
    props.onChange && props.onChange(props.value.filter((value: string) => value !== deleteValue));
  };

  const handleClickAdd = () => {
    const newValue = props.value ?? [];

    const addNum = (inputNumberRef.current?.value as string).split(',');
    addNum.map((v) => {
      v = v.trim();
      if (v && newValue.indexOf(v) === -1) {
        newValue.push(v);
      }
      return v;
    });
    (inputNumberRef.current as HTMLInputElement).value = '';

    props.onChange && props.onChange(newValue);
  };

  const handleMouseDownAdd = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  const handleClearAll = () => {
    props.onChange && props.onChange([]);
  };

  return (
    <Box>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="number_input">{props.label}</InputLabel>
        <OutlinedInput
          fullWidth
          label={props.label}
          type="text"
          autoComplete="current-password"
          inputRef={inputNumberRef}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="add"
                color="primary"
                variant="contained"
                onClick={handleClickAdd}
                onMouseDown={handleMouseDownAdd}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <SimpleList>
        {props.value &&
          props.value.map((value: string) => (
            <Chip key={value} label={value} variant="outlined" color="primary" onDelete={() => handleDelete(value)} />
          ))}
        {props.value && (
          <Chip
            label="CLEAR ALL"
            variant="outlined"
            sx={{
              border: 'none',
              color: 'primary.main',
            }}
            onClick={handleClearAll}
          />
        )}
      </SimpleList>
    </Box>
  );
}
