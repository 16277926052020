import { ConsolidatorEdit } from '../models/consolidator/ConsolidatorEdit';

export const ConsolidatorEditInit: ConsolidatorEdit = {
  id: 0,
  name: '',
  status: 'active',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  company: '',
  address: '',
  address_no: '',
  postcode: '',
  city: '',
  country: '',
  point_of_sale: '',
  ticketing_location: 0,
  original_ticketing_location: 0,
  invoicing_location: 0,
  original_invoicing_location: 0,
  locale: '',
  vat_rate_national: 0,
  vat_rate_continental: 0,
  vat_rate_international: 0,
  iata_number: 0,
  agt: '',
  bst: '',
  form_of_payment: '',
  surcharge_emdn: 0,
  surcharge_zmc: 0,
  footer_left: '',
  footer_center: '',
  footer_right: '',
  psp_id: '',
  currency_code: '',
};
