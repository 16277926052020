import { FormEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import {
  Alert,
  Card,
  CardHeader,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Paper,
  Button,
  Divider,
} from '../../imports';

import AutomaticCheckIn from './components/AutomaticCheckIn';
import CheckboxField from '../../components/CheckboxField';
import CrsList from './components/CrsList';
import LoadingButton from '../../components/LoadingButton';
import LoadingPage from '../../components/LoadingPage';
import NumberMultiInput from '../../components/NumberMultiInput';
import SimpleList from '../../components/SimpleList';
import TransferList from './components/TransferList';

import FunctionalitiesData from '../../models/functionalities/FunctionalitiesData';
import FunctionalitiesGetResponse from '../../models/functionalities/FunctionalitiesGetResponse';
import FunctionalitiesSetRequestItem from '../../models/functionalities/FunctionalitiesSetRequestItem';
import TravelAgency from '../../models/user/TravelAgency';
import TravelAgencyNumberFunctionalities from '../../models/TravelAgencyNumberFunctionalities';

import { ApiFunctionalitiesGet, ApiFunctionalitiesSet } from '../../constants/endpoints';
import { automaticCheckInMaxValue, automaticCheckInMinValue } from '../../constants/utils';
import { FunctionalitiesDataInit } from '../../constants/FunctionalitiesDataInit';
import { DataSendStatusInit } from '../../constants/DataSendStatus/DataSendStatusInit';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useAbortSignal from '../../services/useAbortSignal';
import useCurrentBrands from '../../services/useCurrentBrands';

export default function Functionalities() {
  const [formData, setFormData] = useState<FunctionalitiesData>(FunctionalitiesDataInit);
  const [loadedData, setLoadedData] = useState<FunctionalitiesData>(FunctionalitiesDataInit);
  const [loading, setLoading] = useState<boolean>(true);
  const [sendingData, setSendingData] = useState<boolean>(false);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const abortSignal = useAbortSignal();
  const currentBrands = useCurrentBrands('single');

  const currentTravelAgency = currentBrands[0];

  const checkAutomaticCheckinValues = () => {
    const fieldValues: (number | null)[] = [
      formData.automaticCheckinAirobotMinDays,
      formData.automaticCheckinAirobotMaxDays,
      formData.automaticCheckinAirobotLimit,
    ];

    for (const fieldValue of fieldValues) {
      if (
        fieldValue &&
        !isNaN(fieldValue) &&
        (fieldValue < automaticCheckInMinValue || fieldValue > automaticCheckInMaxValue)
      ) {
        throw new Error('Wrong values in Automatic check-in');
      }
    }
  };

  const loadFormData = useCallback(() => {
    setLoading(true);

    axios
      .post<FunctionalitiesGetResponse>(
        ApiFunctionalitiesGet,
        {
          brand: (currentTravelAgency as TravelAgency).name,
        },
        {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        }
      )
      .then((response) => {
        setLoadedData(response.data.data);
        setFormData(response.data.data);
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [currentTravelAgency, user.token, abortSignal, checkResponseError]);

  const handleReset = () => {
    setFormData(loadedData);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // reset before request
    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    try {
      checkAutomaticCheckinValues();

      const functionalitiesSetRequestItem: FunctionalitiesSetRequestItem = {
        brand: (currentTravelAgency as TravelAgency).name,
        functionalities: formData,
      };

      axios
        .post(ApiFunctionalitiesSet, [functionalitiesSetRequestItem], {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        })
        .then(() =>
          setDataSendStatus({
            open: true,
            success: true,
            message: 'Functionalities updated successfully.',
          })
        )
        .catch(checkResponseError)
        .finally(() => setSendingData(false));
    } catch (e) {
      setSendingData(false);
      setDataSendStatus({
        open: true,
        success: false,
        message: 'Unable to update functionalities.',
      });
    }
  };

  useEffect(() => {
    if (currentTravelAgency) {
      loadFormData();
    }
  }, [currentTravelAgency, loadFormData]);

  const updateAllFields = (fields: Partial<FunctionalitiesData>) => {
    setFormData((prevState) => ({ ...prevState, ...fields }));
  };

  const updateFunctionalitiesFields = (fields: Partial<TravelAgencyNumberFunctionalities>) => {
    setFormData((prevState) => ({
      ...prevState,
      ...(prevState.travelAgencyNumberFunctionalities && {
        travelAgencyNumberFunctionalities: {
          ...prevState.travelAgencyNumberFunctionalities,
          ...fields,
        },
      }),
    }));
  };

  return (
    <Container maxWidth="lg" component="form" onSubmit={handleSubmit}>
      {!currentBrands.length ? (
        <Alert severity="info">Select a brand to change functionalities.</Alert>
      ) : (
        <>
          {loading && <LoadingPage />}
          {!loading && (
            <>
              <Grid container columns={2}>
                <Grid item lg={1} xs={2}>
                  <Card variant="island">
                    <CardHeader title="Source priority" />
                    <CardContent>
                      <TransferList
                        name="crsPriorityList"
                        items={formData.crsList}
                        selected={formData.crsPriorityList}
                        updateFields={updateAllFields}
                      />
                    </CardContent>
                  </Card>
                  <Card variant="island">
                    <CardHeader title="Features" />
                    <CardContent>
                      <CrsList
                        name="seatDisplay"
                        label="Seat map display"
                        items={formData.crsList}
                        selected={formData.seatDisplayCrsList}
                        enabled={formData.seatDisplayEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="meal"
                        label="Meal selection"
                        items={formData.crsList}
                        selected={formData.mealCrsList}
                        enabled={formData.mealEnabled}
                        updateFields={updateAllFields}
                      />
                      <Divider />
                      <CrsList
                        name="profile"
                        label="Profile"
                        enabled={formData.profileEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="frequentFlyerNumber"
                        label="Frequent flyer number"
                        enabled={formData.frequentFlyerNumberEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="eTicketsDisplay"
                        label="E-tickets display"
                        enabled={formData.eTicketsDisplayEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="invoiceDisplay"
                        label="Invoice display"
                        enabled={formData.invoiceDisplayEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="covidTest"
                        label="Covid test"
                        enabled={formData.covidTestEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="co2Compensation"
                        label="CO2 compensation"
                        enabled={formData.co2CompensationEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="pdfTravelplanCreate"
                        label="PDF travel plan"
                        enabled={formData.pdfTravelplanCreateEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="insuranceSetooBanner"
                        label="Insurance Setoo banner"
                        enabled={formData.insuranceSetooBannerEnabled}
                        updateFields={updateAllFields}
                      />
                    </CardContent>
                  </Card>
                  <Card variant="island">
                    <CardHeader title="Restrictions for certain agencies (optional)" />
                    <CardContent>
                      <NumberMultiInput
                        id="travelAgencyNumbers"
                        name="travelAgencyNumbers"
                        label="Travel agencies"
                        value={formData.travelAgencyNumbers.map((item: number) => item.toString())}
                        onChange={(values: string[]) =>
                          updateAllFields({ travelAgencyNumbers: values.map((value: string) => parseInt(value)) })
                        }
                      />
                      <SimpleList sx={{ mt: 2 }}>
                        <CheckboxField
                          label="Seat map display"
                          checked={formData.travelAgencyNumberFunctionalities?.seatDisplayEnabled}
                          onChange={(checked) => updateFunctionalitiesFields({ seatDisplayEnabled: checked })}
                        />
                        <CheckboxField
                          label="Chargeable seats"
                          checked={formData.travelAgencyNumberFunctionalities?.seatReservationChargeableEnabled}
                          onChange={(checked) =>
                            updateFunctionalitiesFields({ seatReservationChargeableEnabled: checked })
                          }
                        />
                        <CheckboxField
                          label="Non-chargeable seats"
                          checked={formData.travelAgencyNumberFunctionalities?.seatReservationNonChargeableEnabled}
                          onChange={(checked) =>
                            updateFunctionalitiesFields({ seatReservationNonChargeableEnabled: checked })
                          }
                        />
                        <CheckboxField
                          label="Meal selection"
                          checked={formData.travelAgencyNumberFunctionalities?.mealEnabled}
                          onChange={(checked) => updateFunctionalitiesFields({ mealEnabled: checked })}
                        />
                        <CheckboxField
                          label="Extras"
                          checked={formData.travelAgencyNumberFunctionalities?.extrasEnabled}
                          onChange={(checked) => updateFunctionalitiesFields({ extrasEnabled: checked })}
                        />
                        <CheckboxField
                          label="Baggage"
                          checked={formData.travelAgencyNumberFunctionalities?.baggageEnabled}
                          onChange={(checked) => updateFunctionalitiesFields({ baggageEnabled: checked })}
                        />
                        <CheckboxField
                          label="Cash upgrade"
                          checked={formData.travelAgencyNumberFunctionalities?.cashUpgradeEnabled}
                          onChange={(checked) => updateFunctionalitiesFields({ cashUpgradeEnabled: checked })}
                        />
                        <CheckboxField
                          label="Baggage protection"
                          checked={formData.travelAgencyNumberFunctionalities?.insuranceBlueribbonbagsEnabled}
                          onChange={(checked) =>
                            updateFunctionalitiesFields({ insuranceBlueribbonbagsEnabled: checked })
                          }
                        />
                        <CheckboxField
                          label="Transfer"
                          checked={formData.travelAgencyNumberFunctionalities?.transferDistribusionEnabled}
                          onChange={(checked) => updateFunctionalitiesFields({ transferDistribusionEnabled: checked })}
                        />
                      </SimpleList>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={1} xs={2}>
                  <Card variant="island">
                    <CardHeader
                      title="Ancillaries"
                      action={
                        <FormControlLabel
                          label="Shopping enabled"
                          labelPlacement="start"
                          control={
                            <Switch
                              name="shoppingEnabled"
                              checked={formData.shoppingEnabled}
                              onChange={(_event, checked) => updateAllFields({ shoppingEnabled: checked })}
                            />
                          }
                        />
                      }
                    />
                    <CardContent>
                      <CrsList
                        name="seatReservationChargeable"
                        label="Chargeable seats"
                        items={formData.crsList}
                        selected={formData.seatReservationChargeableCrsList}
                        enabled={formData.seatReservationChargeableEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="seatReservationNonChargeable"
                        label="Non-chargeable seats"
                        items={formData.crsList}
                        selected={formData.seatReservationNonChargeableCrsList}
                        enabled={formData.seatReservationNonChargeableEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="baggage"
                        label="Baggage"
                        items={formData.crsList}
                        selected={formData.baggageCrsList}
                        enabled={formData.baggageEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="cashUpgrade"
                        label="Cash upgrade"
                        items={formData.crsList}
                        selected={formData.cashUpgradeCrsList}
                        enabled={formData.cashUpgradeEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="extras"
                        label="Extras"
                        items={formData.crsList}
                        selected={formData.extrasCrsList}
                        enabled={formData.extrasEnabled}
                        updateFields={updateAllFields}
                      />
                      <Divider />
                      <CrsList
                        name="insuranceBlueribbonbags"
                        label="Baggage protection"
                        enabled={formData.insuranceBlueribbonbagsEnabled}
                        updateFields={updateAllFields}
                      />
                      <CrsList
                        name="transferDistribusion"
                        label="Transfer"
                        enabled={formData.transferDistribusionEnabled}
                        updateFields={updateAllFields}
                      />
                      <Divider />
                      <AutomaticCheckIn {...formData} updateFields={updateAllFields} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Paper variant="action">
                <LoadingButton type="submit" text="Save" sendingData={sendingData} />
                <Button color="primary" variant="outlined" onClick={handleReset}>
                  Reset
                </Button>
              </Paper>
            </>
          )}
        </>
      )}
    </Container>
  );
}
