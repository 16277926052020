import axios from 'axios';
import { FormEvent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  ClearIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  TextField,
} from '../../../imports';

import PasswordTextField from '../../../components/PasswordTextField';
import LoadingButton from '../../../components/LoadingButton';

import password_validate from '../../../utils/validators/password';

import { ApiUsersChangePassword } from '../../../constants/endpoints';
import useAbortSignal from '../../../services/useAbortSignal';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import { useAuth } from '../../../services/useAuth';

interface ChangePasswordProps {
  open: boolean;
  closeCallback: any;
}

export default function ChangePassword(props: ChangePasswordProps) {
  const [open, setOpen] = useState(props.open);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const { user } = useAuth();
  const [sendingData, setSendingData] = useState<boolean>(false);
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const abortSignal = useAbortSignal();

  const handleClose = () => {
    handleReset();
    setOpen(false);
    props.closeCallback();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!currentPassword || password_validate(password) || password !== passwordConfirmation) {
      return;
    }

    setSendingData(true);
    axios
      .post(
        ApiUsersChangePassword,
        {
          current_password: currentPassword,
          password: password,
          password_confirmation: passwordConfirmation,
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      )
      .then(() => {
        setDataSendStatus({
          open: true,
          message: 'Password changed successfully.',
          success: true,
        });
        setTimeout(() => handleClose(), 500);
      })
      .catch((e) => {
        checkResponseError(e);

        setDataSendStatus({
          open: true,
          message: 'Unable to change password.',
          success: false,
        });
      })
      .finally(() => setSendingData(false));
  };

  const handleReset = () => {
    setCurrentPassword('');
    setPassword('');
    setPasswordConfirmation('');
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Change password
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              fullWidth
              variant="outlined"
              name="currentPassword"
              label="Current password"
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.currentTarget.value)}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <PasswordTextField
              required
              fullWidth
              variant="outlined"
              name="password"
              label="New password"
              type="password"
              id="password"
              value={password}
              onChange={(val) => setPassword(val)}
            />
          </FormControl>

          <FormControl fullWidth margin="normal" sx={{ mt: 0 }}>
            <PasswordTextField
              required
              fullWidth
              variant="outlined"
              name="passwordConfirmation"
              label="New password (repeat)"
              type="password"
              id="passwordConfirmation"
              value={passwordConfirmation}
              confirm={true}
              confirmValue={password}
              onChange={(val) => setPasswordConfirmation(val)}
            />
          </FormControl>

          <Paper variant="action" sx={{ ml: 0 }}>
            <LoadingButton type="submit" text="Save" sendingData={sendingData} />
            <Button color="primary" variant="outlined" onClick={handleReset}>
              Reset
            </Button>
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
