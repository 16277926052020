import { createTheme } from '../imports';

export const GlobalTheme = createTheme({
  palette: {
    background: {
      default: '#EFEFEF',
    },
    primary: {
      main: '#2589CC',
    },
    success: {
      main: '#009245',
    },
  },
  typography: {
    fontFamily: 'Arial',
  },
});
