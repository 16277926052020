import axios from 'axios';
import { endOfMonth, format, parse, startOfMonth } from 'date-fns';
import { useState } from 'react';

import {
  AdapterDateFns,
  Box,
  Button,
  CircularProgress,
  DataGrid,
  DatePicker,
  Divider,
  Grid,
  GridCellParams,
  GridColDef,
  GridSortModel,
  LocalizationProvider,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  WarningIcon,
} from '../../imports';

import DropdownField from '../../components/DropdownField';

import { ApiPaymentsGet, ApiPaymentsSet } from '../../constants/endpoints';
import { DateFormatApi, DateFormatApiExtended, DateFormatInput, DateFormatInputExtended } from '../../constants/utils';
import PaymentsItemData from '../../models/payments/PaymentsItemData';
import { PaymentStatus } from '../../models/payments/PaymentStatus';
import PaymentsGetResponse from '../../models/payments/PaymentsGetResponse';
import PaymentsSetRequest from '../../models/payments/PaymentsSetRequest';
import PaymentsSetResponse from '../../models/payments/PaymentsSetResponse';
import PaymentsGetRequest from '../../models/payments/PaymentsGetRequest';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useAbortSignal from '../../services/useAbortSignal';
import usePageConfig from '../../services/usePageConfig';

const GreenBox = styled(Box)({
  backgroundColor: '#D8EEE3',
  border: '1px solid',
  borderColor: '#009245',
});

const RedBox = styled(Box)({
  backgroundColor: '#FDEDE9',
  color: '#ED4B2A',
  border: '1px solid',
  borderColor: '#ED4B2A',
});

const StyledDataGrid = styled(DataGrid)({
  border: 0,
  '& .MuiDataGrid-row': {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  },
  '& .data-grid-payments--error': {
    backgroundColor: '#FDEDE9',
    '&:hover': {
      backgroundColor: '#FDEDE9',
    },
    '&.Mui-selected': {
      backgroundColor: '#FDEDE9',
      '&:hover': {
        backgroundColor: '#FDEDE9',
      },
    },
  },
});

export default function Payments() {
  const [loading, setLoading] = useState<boolean>(false);
  const [sendingData, setSendingData] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'created', sort: 'asc' }]);
  const [payments, setPayments] = useState<PaymentsItemData[]>([]);
  const [dateFrom, setDateFrom] = useState<Date | null>(startOfMonth(new Date()));
  const [dateTo, setDateTo] = useState<Date | null>(endOfMonth(new Date()));

  const { user } = useAuth();
  const { checkResponseError } = useDataSendStatus();
  const { currentPage } = usePageConfig();
  const abortSignal = useAbortSignal();

  const paymentStatuses = Object.values(PaymentStatus).map((value: string) => {
    return {
      label: value,
      value: value,
      hidden: value === PaymentStatus.Error,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'isOriginalFailedPayment',
      headerName: 'Originally Failed',
      width: 80,
      renderCell: (params: GridCellParams) => {
        return params.value ? <WarningIcon color="error" /> : '';
      },
    },
    { field: 'filekey', headerName: 'Filekey', flex: 1, type: 'string' },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      type: 'number',
      valueFormatter: (params) => params.value + ' €',
    },
    { field: 'crs', headerName: 'GDS', flex: 1 },
    { field: 'brand', headerName: 'Brand', flex: 1 },
    {
      field: 'date',
      headerName: 'Created',
      flex: 1,
      type: 'string',
      valueFormatter: (params) =>
        format(parse(params?.value, DateFormatApiExtended, new Date()), DateFormatInputExtended),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <DropdownField
          items={paymentStatuses}
          value={params.value}
          disabled={sendingData}
          onSelect={(newValue: string, oldValue: string) => {
            handleUpdate(params.row, newValue, oldValue);
          }}
        />
      ),
    },
  ];

  const handleUpdate = (row: any, newValue: string, oldValue: string) => {
    setSendingData(true);

    const requestData: PaymentsSetRequest = {
      statusOld: oldValue,
      statusNew: newValue,
      paymentId: row.paymentId,
      purchaseOrderId: row.purchaseOrderId,
      filekey: row.filekey,
    };

    axios
      .post<PaymentsSetResponse>(ApiPaymentsSet, requestData, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then((response) => {
        if (response.data.meta.success) {
          setPayments((prevState: PaymentsItemData[]) =>
            prevState.map((row: PaymentsItemData) => {
              row.status = row.filekey === response.data.data.filekey ? (newValue as PaymentStatus) : row.status;
              return row;
            })
          );
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const handleGenerate = () => {
    setMessage(null);
    setLoading(true);

    const requestData: PaymentsGetRequest = {
      from: format(dateFrom as Date, DateFormatApi),
      to: format(dateTo as Date, DateFormatApi),
    };

    axios
      .post<PaymentsGetResponse>(ApiPaymentsGet, requestData, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then((response) => {
        if (response.data.meta.success) {
          setPayments(response.data.data.payments);

          if (response.data.data.totals.total === 0) {
            setMessage(
              'No payments found from ' +
                format(dateFrom as Date, DateFormatInput) +
                ' to ' +
                format(dateTo as Date, DateFormatInput)
            );
          }
        }
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  };

  return (
    <Paper variant="island" sx={{ position: 'relative' }}>
      <Typography variant="h5" marginBottom={2}>
        {currentPage.name}
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <Box marginTop={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  label="Date from"
                  value={dateFrom}
                  inputFormat={DateFormatInput}
                  maxDate={dateTo}
                  onChange={(newValue) => setDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="Date to"
                  value={dateTo}
                  inputFormat={DateFormatInput}
                  minDate={dateFrom}
                  onChange={(newValue) => setDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  sx={{ position: 'relative' }}
                  variant="contained"
                  color="success"
                  disabled={loading}
                  onClick={handleGenerate}>
                  GENERATE
                  {loading && <CircularProgress size={32} sx={{ position: 'absolute', left: 'calc(50% - 16px)' }} />}
                </Button>
              </Stack>
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item xs={4}>
          {payments.length > 0 && (
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={5}>
                <RedBox padding={2} marginX={1}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      Error:
                    </Grid>
                    <Grid item>
                      {payments.reduce(
                        (prevValue, currentValue) => prevValue + (currentValue.status === PaymentStatus.Error ? 1 : 0),
                        0
                      )}
                    </Grid>
                  </Grid>
                </RedBox>
              </Grid>
              <Grid item xs={7}>
                <GreenBox padding={2} marginLeft={1}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      Total payments:
                    </Grid>
                    <Grid item>{payments.length}</Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      Completed payments:
                    </Grid>
                    <Grid item>
                      {payments.reduce(
                        (prevValue, currentValue) =>
                          prevValue + (currentValue.status === PaymentStatus.Complete ? 1 : 0),
                        0
                      )}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      OriginalFailed:
                    </Grid>
                    <Grid item>
                      {payments.reduce(
                        (prevValue, currentValue) => prevValue + (currentValue.isOriginalFailedPayment ? 1 : 0),
                        0
                      )}
                    </Grid>
                  </Grid>
                </GreenBox>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {payments.length > 0 && (
        <Box>
          <Divider sx={{ marginTop: 3 }} />
          <StyledDataGrid
            autoHeight
            disableSelectionOnClick
            disableColumnMenu
            columns={columns}
            rows={payments}
            sortModel={sortModel}
            loading={loading || sendingData}
            onSortModelChange={(model: GridSortModel) => setSortModel(model)}
            getRowId={(row) => row.paymentId}
            getRowClassName={(params) => 'MuiDataGrid-row data-grid-payments--' + params.row.status}
          />
        </Box>
      )}
      {message && (
        <Box>
          <Divider sx={{ marginTop: 3 }} />
          <Typography sx={{ marginTop: 3 }}>{message}</Typography>
        </Box>
      )}
    </Paper>
  );
}
