import axios from 'axios';
import { startOfMonth, endOfMonth, format, parse } from 'date-fns';
import { ChangeEvent, useState } from 'react';

import {
  AdapterDateFns,
  Alert,
  Box,
  Checkbox,
  DataGrid,
  DatePicker,
  Divider,
  FormControlLabel,
  FormGroup,
  GridColDef,
  GridValueFormatterParams,
  LocalizationProvider,
  Paper,
  Stack,
  TextField,
  Typography,
} from '../../imports';

import MultipleSelectCheckmarks from './components/MultipleSelectCheckmarks';
import LoadingButton from '../../components/LoadingButton';

import { ApiReportBookingsDownload, ApiReportBookingsGet } from '../../constants/endpoints';
import { DateFormatApi, DateFormatApiExtended, DateFormatInput, DateFormatInputExtended } from '../../constants/utils';
import { gds } from '../../constants/gds';

import { BookingsData } from '../../models/report/BookingsData';
import TravelAgency from '../../models/user/TravelAgency';

import { useAuth } from '../../services/useAuth';
import useAbortSignal from '../../services/useAbortSignal';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useCurrentBrands from '../../services/useCurrentBrands';

import { save_file } from '../../utils/utils';

const columns: GridColDef[] = [
  { field: 'filekey', headerName: 'Filekey', flex: 1 },
  { field: 'lastname', headerName: 'Search Name', flex: 1 },
  { field: 'booking_reference', headerName: 'Booking Reference', flex: 1 },
  { field: 'branding', headerName: 'Brand', flex: 1 },
  { field: 'sub_domain', headerName: 'Sub-domain', flex: 1 },
  { field: 'travel_agency_number', headerName: 'Travel Agency Number', flex: 1 },
  { field: 'crs', headerName: 'GDS', flex: 1 },
  { field: 'type', headerName: 'Type', flex: 1 },
  {
    field: 'created_at',
    headerName: 'Created (UTC)',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) =>
      format(parse(params.value, DateFormatApiExtended, new Date()), DateFormatInputExtended),
  },
  { field: 'device_category', headerName: 'Device Category', flex: 1 },
  { field: 'device_os', headerName: 'Device OS', flex: 1 },
  { field: 'source', headerName: 'Source', flex: 1 },
];

export default function Views() {
  const [data, setData] = useState<BookingsData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  const { user } = useAuth();
  const currentBrands = useCurrentBrands('multiple');
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const abortSignal = useAbortSignal();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    device_category: false,
    device_os: false,
    source: false,
  });

  const [options, setOptions] = useState({
    device_category: false,
    device_os: false,
    source: false,
  });
  const [crs, setCrs] = useState<typeof gds>([]);
  const [dateFrom, setDateFrom] = useState<Date | null>(startOfMonth(new Date()));
  const [dateTo, setDateTo] = useState<Date | null>(endOfMonth(new Date()));

  const crypt = window.btoa;

  const handleGenerate = () => {
    setLoading(true);

    axios
      .post(
        ApiReportBookingsGet,
        {
          brands: currentBrands.map((brand) => brand.name),
          from: format(dateFrom as Date, DateFormatApi),
          to: format(dateTo as Date, DateFormatApi),
          options: Object.entries(options)
            .filter(([, value]) => value)
            .map(([key]) => key),
          crs: crs,
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      )
      .then((data) => {
        setData(
          data.data.data.map((item: any) => ({
            ...item,
            id: Math.random(),
          }))
        );
        setColumnVisibilityModel(options);
      })
      .catch((error) => {
        setData([]);
        checkResponseError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleDownload = () => {
    setDownloading(true);

    const requestParams = [format(dateFrom as Date, DateFormatApi), format(dateTo as Date, DateFormatApi)];
    const selectedTravelAgencies = currentBrands.map((ta: TravelAgency) => ta.name);
    const selectedOptions: string[] = Object.entries(options)
      .filter(([, value]) => value)
      .map(([key]) => key);

    let url: string = '/' + crypt(requestParams.join(',')) + '/' + crypt(selectedTravelAgencies.join(','));
    url += selectedOptions.length > 0 ? '/' + crypt(selectedOptions.join(',')) : '';
    url += crs.length > 0 ? '/' + crypt(crs.join(',')) : '';

    axios
      .get(ApiReportBookingsDownload + url, { responseType: 'blob', signal: abortSignal })
      .then(function (response) {
        if (response.headers['content-type'] !== 'application/json') {
          save_file(response);
        } else if (!response.data.meta.success) {
          setDataSendStatus({ open: true, success: false, message: response.data.message });
        }
      })
      .catch(checkResponseError)
      .finally(() => {
        setDownloading(false);
      });
  };

  const handleOptionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOptions({
      ...options,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCrsItemsChange = (selected: string[]) => {
    setCrs(selected);
  };

  return (
    <Paper variant="island">
      <Typography variant="h5" marginBottom={2}>
        Views
      </Typography>

      {currentBrands && currentBrands.length === 0 && (
        <Alert severity="info">You must select at least one travel agency and pick the desire dates.</Alert>
      )}

      {currentBrands && currentBrands.length === 0 && (
        <Box sx={{ mt: 2 }}>
          <Divider />
        </Box>
      )}

      <Typography variant="h6" sx={{ mt: 3 }}>
        Options
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox name="device_category" checked={options.device_category} onChange={handleOptionsChange} />}
          label="Device category (PNRs not unique)"
        />
        <FormControlLabel
          control={<Checkbox name="device_os" checked={options.device_os} onChange={handleOptionsChange} />}
          label="Device operating system (PNRs not unique)"
        />
        <FormControlLabel
          control={<Checkbox name="source" checked={options.source} onChange={handleOptionsChange} />}
          label="Source (PNRs not unique)"
        />
        <MultipleSelectCheckmarks name="crs" label="GDS" items={gds} onChange={handleCrsItemsChange} />
      </FormGroup>

      <Box sx={{ mt: 2 }}>
        <Divider />
      </Box>

      <Box marginTop={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Date from"
              value={dateFrom}
              inputFormat={DateFormatInput}
              maxDate={dateTo}
              onChange={(newValue) => setDateFrom(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <DatePicker
              label="Date to"
              value={dateTo}
              inputFormat={DateFormatInput}
              minDate={dateFrom}
              onChange={(newValue) => setDateTo(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <LoadingButton
              text="GENERATE REPORT"
              disabled={!currentBrands.length}
              sendingData={loading}
              onClick={handleGenerate}
            />
          </Stack>
        </LocalizationProvider>
      </Box>

      {data.length > 0 && (
        <>
          <Divider sx={{ my: 2 }} />

          <DataGrid
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            columnVisibilityModel={columnVisibilityModel}
            columns={columns}
            loading={loading}
            pageSize={25}
            rowsPerPageOptions={[25, 50, 100]}
            rows={data}
            sx={{ border: 0, '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' } }}
          />

          <LoadingButton
            size="large"
            variant="text"
            sx={{ my: 2, boxShadow: 2 }}
            text="DOWNLOAD REPORT"
            sendingData={downloading}
            onClick={handleDownload}
          />
        </>
      )}
    </Paper>
  );
}
