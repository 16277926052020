import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
} from '../../../imports';

import HistoryCard from '../../../components/HistoryCard';
import LoadingPage from '../../../components/LoadingPage';
import LoadingButton from '../../../components/LoadingButton';

import Brand from '../../../models/Brand';
import { UserEdit } from '../../../models/user/UserEdit';

import { UserEditInit } from '../../../constants/UserEditInit';
import { UrlUsers } from '../../../constants/urls';
import { ApiUsers, ApiUsersBrands } from '../../../constants/endpoints';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { Salutations } from '../../../constants/Salutations';

import { useAuth } from '../../../services/useAuth';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';
import useAbortSignal from '../../../services/useAbortSignal';

import { ucfirst } from '../../../utils/utils';

export default function UsersEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<UserEdit>(UserEditInit);
  const [loadedFormData, setLoadedFormData] = useState<UserEdit>(UserEditInit);
  const [brandList, setBrandList] = useState<Brand[]>([]);
  const [brandValue, setBrandValue] = useState<string[]>([]);

  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { userId } = useParams();
  const abortSignal = useAbortSignal();

  const onRoleChange = (event: SelectChangeEvent) => {
    const fd = Object.assign({}, formData);
    fd.role = event.target.value as string;
    setFormData(fd);
  };

  const onSalutationChange = (event: SelectChangeEvent) => {
    const fd = Object.assign({}, formData);
    fd.salutation = event.target.value as string;
    setFormData(fd);
  };

  const onStatusChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.checked;
    const fd = Object.assign({}, formData);
    fd.status = newValue ? 'active' : 'inactive';
    setFormData(fd);
  };

  const onBrandsChange = (event: SelectChangeEvent<typeof brandValue>) => {
    const {
      target: { value },
    } = event;

    const newValue = typeof value === 'string' ? value.split(',') : value;
    const fd = Object.assign({}, formData);
    fd.brands = newValue;
    setFormData(fd);
    setBrandValue(newValue);
  };

  const updateLoadedFormData = useCallback(
    (data: UserEdit) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name);
      setBrandValue(data.brands);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): UserEdit {
    return {
      id: response.id as number,
      salutation: (response.salutation as string) ?? '',
      first_name: response.first_name as string,
      last_name: response.last_name as string,
      name: response.name as string,
      email: response.email as string,
      phone: response.phone as string,
      status: response.status as 'active' | 'inactive',
      role: response.role as string,
      created_at: response.created_at as string,
      updated_at: response.updated_at as string,
      created_by: response.created_by as string,
      updated_by: response.updated_by as string,
      brands: response.brands.map((b: any) => b as string),
    };
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let request: Promise<any>;
    if (action === 'edit') {
      request = axios.put(ApiUsers + '/' + userId, formData, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      });
    } else {
      request = axios.post(ApiUsers, formData, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      });
    }

    request
      .then((data) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(data.data.data));
        } else {
          setDataSendStatus({ open: true, success: true, message: 'User created' });
          navigate(UrlUsers);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadBrands = useCallback(() => {
    setLoading(true);
    axios
      .get(ApiUsersBrands, {
        headers: { 'Auth-User': user.token },
        signal: abortSignal,
      })
      .then((data) => {
        setBrandList(data.data.data ?? []);
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [checkResponseError, user.token, abortSignal]);

  const loadFormData = useCallback(() => {
    const uid = Number(userId);
    if (uid > 0) {
      setLoading(true);
      Promise.all([
        axios.get(ApiUsersBrands, {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        }),
        axios.get(ApiUsers + '/' + userId, {
          headers: { 'Auth-User': user.token },
          signal: abortSignal,
        }),
      ])
        .then((responses) => {
          setBrandList(responses[0].data.data ?? []);

          updateLoadedFormData(getDataFromResponse((responses[1].data.data as UserEdit) ?? UserEditInit));
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add user');
      loadBrands();
    }
  }, [userId, user.token, abortSignal, checkResponseError, loadBrands, setTitle, updateLoadedFormData]);

  useEffect(() => {
    const uid = Number(userId);
    if (uid > 0) {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [userId, loadFormData]);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <FormControlLabel
            sx={{ ml: 0, float: 'right' }}
            control={<Switch name="status" checked={formData.status === 'active'} onChange={onStatusChange} />}
            label={ucfirst(formData.status)}
          />
          <Grid container columns={2}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Details" />
                <CardContent>
                  <FormControl margin="normal" sx={{ width: '130px' }}>
                    <InputLabel id="salutation">Salutation</InputLabel>
                    <Select
                      labelId="salutation"
                      id="salutation"
                      value={formData.salutation}
                      label="Salutation"
                      name="salutation"
                      onChange={onSalutationChange}>
                      {Salutations.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.salutation}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="first_name">First name</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="first_name"
                      id="first_name"
                      label="First name"
                      type="text"
                      value={formData.first_name}
                      onChange={onChange}
                    />
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="last_name">Last name</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="last_name"
                      id="last_name"
                      label="Last name"
                      type="text"
                      value={formData.last_name}
                      onChange={onChange}
                    />
                  </FormControl>

                  <Box maxWidth="sm">
                    <FormControl fullWidth margin="normal" sx={{ mt: 2 }}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        fullWidth
                        name="email"
                        id="email"
                        label="Email"
                        type="text"
                        value={formData.email}
                        onChange={onChange}
                      />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="phone">Phone</InputLabel>
                      <OutlinedInput
                        fullWidth
                        name="phone"
                        id="phone"
                        label="Phone"
                        type="text"
                        value={formData.phone}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Details" />
                <CardContent>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                      labelId="role"
                      id="role"
                      value={formData.role}
                      label="Role"
                      name="role"
                      onChange={onRoleChange}>
                      {['admin', 'operator', 'user'].map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.role}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <InputLabel id="demo-multiple-checkbox-label">Brands</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      name="brands"
                      multiple
                      value={brandValue}
                      onChange={onBrandsChange}
                      input={<OutlinedInput label="Brands" />}
                      renderValue={() =>
                        brandList
                          .filter((brand) => formData.brands.indexOf(brand.id) !== -1)
                          .map((b) => b.name)
                          .join(', ')
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 350,
                            width: 250,
                          },
                        },
                      }}>
                      {brandList.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                          <Checkbox checked={formData.brands.indexOf(brand.id) !== -1} />
                          <ListItemText primary={brand.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
              <HistoryCard
                created_by={formData.created_by}
                created_at={formData.created_at}
                updated_by={formData.updated_by}
                updated_at={formData.updated_at}
              />
            </Grid>
          </Grid>
          <Stack direction="row" ml={1}>
            <LoadingButton text="Save" sendingData={sendingData} />

            <Button
              color="primary"
              variant="outlined"
              onClick={() => updateLoadedFormData(loadedFormData)}
              sx={{ ml: 2 }}>
              Reset
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
