import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Login from '../pages/Login';
import ResetPasswordEmail from '../pages/ResetPassword/ResetPasswordEmail';
import ResetPasswordPassword from '../pages/ResetPassword/ResetPasswordPassword';
import NotFound from '../pages/NotFound';

import { UrlDashboard, UrlLogin, UrlResetPassword } from '../constants/urls';

export default function RouterNotAuthorized() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path={UrlDashboard} element={<Login />} />
        <Route path={UrlLogin} element={<Login />} />
        <Route path={UrlResetPassword} element={<ResetPasswordEmail />} />
        <Route path={UrlResetPassword + '/:token/:email'} element={<ResetPasswordPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );
}
