export const ApiLogin = '/api/dashboard/user/login';
export const ApiBrandProfileGet = '/api/dashboard/brand-profile/get';
export const ApiBrandProfileSet = '/api/dashboard/brand-profile/set';
export const ApiFunctionalitiesGet = '/api/dashboard/functionalities/get';
export const ApiFunctionalitiesSet = '/api/dashboard/functionalities/set';
export const ApiMarkupsGet = '/api/dashboard/markups/get';
export const ApiMarkupsSet = '/api/dashboard/markups/set';
export const ApiPreferencesGet = '/api/dashboard/preferences/get';
export const ApiPreferencesSet = '/api/dashboard/preferences/set';
export const ApiReportBookingsGet = '/api/dashboard/reports/bookings/generate';
export const ApiReportBookingsDownload = '/api/dashboard/reports/bookings/download';
export const ApiReportSalesGet = '/api/dashboard/reports/sales/generate';
export const ApiReportSalesDownload = '/api/dashboard/reports/sales/download';
export const ApiUsers = '/api/dashboard/user';
export const ApiConsolidators = '/api/dashboard/consolidators';
export const ApiUsersBrands = '/api/dashboard/user/brands';
export const ApiUsersStatus = '/api/dashboard/user/status';
export const ApiPaymentsGet = '/api/dashboard/payments/get';
export const ApiPaymentsSet = '/api/dashboard/payments/set';
export const ApiDashboardBookingGet = '/api/dashboard/booking/get';
export const ApiDashboardSalesGet = '/api/dashboard/sales/get';
export const ApiUsersResetPasswordSendEmail = '/api/dashboard/user/password-email';
export const ApiUsersResetPasswordReset = '/api/dashboard/user/password-reset';
export const ApiUsersChangePassword = '/api/dashboard/user/password-change';
