export const UrlProfile = '/profile';
export const UrlDashboard = '/';
export const UrlConsolidators = '/consolidators';
export const UrlBrand = '/brand';
export const UrlFunctionalities = '/functionalities';
export const UrlMarkups = '/markups';
export const UrlPreferences = '/preferences';
export const UrlUsers = '/users';
export const UrlPurchases = '/purchases';
export const UrlViews = '/views';
export const UrlPayments = '/payments';
export const UrlLogin = '/login';
export const UrlResetPassword = '/reset-password';
