import { useState } from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from '../../../imports';

import PreferencesMessageItem from './PreferencesMessageItem';

import PreferencesItemData from '../../../models/preferences/PreferencesItemData';
import FunctionalitiesData from '../../../models/functionalities/FunctionalitiesData';

interface PreferencesMessageProp {
  name: string;
  label: string;
  locale: string;
  locales: string[];
  messages: PreferencesItemData[];
  enabled: boolean;
  onChangeFunctionalities: (
    fields: Partial<Pick<FunctionalitiesData, 'hintBarErrorEnabled' | 'hintUpsellEnabled'>>
  ) => void;
  onChangePreferences: (item: PreferencesItemData) => void;
}

export default function PreferencesMessage({
  name,
  label,
  locales,
  messages,
  enabled,
  onChangeFunctionalities,
  onChangePreferences,
  ...props
}: PreferencesMessageProp) {
  const [locale, setLocale] = useState<string>(props.locale);

  const handleChangeFunctionalities = (event: any, value: any) =>
    onChangeFunctionalities({ [event.target.name]: value });
  const handleChangePreferences = (item: PreferencesItemData) => onChangePreferences(item);

  return (
    <Box sx={{ m: 1 }}>
      <FormControlLabel
        label={label}
        control={<Switch name={name + 'Enabled'} checked={enabled} onChange={handleChangeFunctionalities} />}
      />
      {enabled && (
        <>
          <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <InputLabel id={'messages-' + name + 'Locale-label'}>Language</InputLabel>
                <Select
                  labelId={'messages-' + name + 'Locale-label'}
                  label="Language"
                  value={locale}
                  onChange={(event: SelectChangeEvent) => setLocale(event.target.value as string)}>
                  {locales.map((localeItem: string) => (
                    <MenuItem key={'messages-' + name + 'Locale-' + localeItem + '-MenuItem'} value={localeItem}>
                      {localeItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <PreferencesMessageItem
            message={messages.find((item) => item.locale === locale) as PreferencesItemData}
            onChange={handleChangePreferences}
          />
        </>
      )}
    </Box>
  );
}
