import { Grid, FormControl, FormControlLabel, Switch } from '../../../imports';

import NumberField from '../../../components/NumberField';

import {
  automaticCheckInMaxValue,
  automaticCheckInMaxValueError,
  automaticCheckInMinValue,
  automaticCheckInMinValueError,
} from '../../../constants/utils';
import FunctionalitiesData from '../../../models/functionalities/FunctionalitiesData';

type AutomaticCheckInData = Pick<
  FunctionalitiesData,
  | 'automaticCheckinAirobotEnabled'
  | 'automaticCheckinAirobotMinDays'
  | 'automaticCheckinAirobotMaxDays'
  | 'automaticCheckinAirobotLimit'
>;

type AutomaticCheckInProp = AutomaticCheckInData & {
  updateFields: (fields: Partial<AutomaticCheckInData>) => void;
};

export default function AutomaticCheckIn({
  automaticCheckinAirobotEnabled,
  automaticCheckinAirobotMinDays,
  automaticCheckinAirobotMaxDays,
  automaticCheckinAirobotLimit,
  updateFields,
}: AutomaticCheckInProp) {
  const handleChange = (event: any, value: any) => updateFields({ [event.target.name]: value });

  return (
    <Grid container spacing={1} columnSpacing={1}>
      <Grid item>
        <FormControlLabel
          sx={{ m: 0.5 }}
          label="Automatic check-in via Airobot"
          control={
            <Switch
              name="automaticCheckinAirobotEnabled"
              checked={automaticCheckinAirobotEnabled}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth margin="dense">
          <NumberField
            id="automaticCheckinAirobotMinDays"
            name="automaticCheckinAirobotMinDays"
            label="Departure due in"
            disabled={!automaticCheckinAirobotEnabled}
            value={automaticCheckinAirobotMinDays?.toString() ?? ''}
            minValue={automaticCheckInMinValue}
            minValueErrorText={automaticCheckInMinValueError}
            maxValue={automaticCheckInMaxValue}
            maxValueErrorText={automaticCheckInMaxValueError}
            onlyInteger={true}
            helperText="minimum days"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <NumberField
            id="automaticCheckinAirobotMaxDays"
            name="automaticCheckinAirobotMaxDays"
            label="Departure due in"
            disabled={!automaticCheckinAirobotEnabled}
            value={automaticCheckinAirobotMaxDays?.toString() ?? ''}
            minValue={automaticCheckInMinValue}
            minValueErrorText={automaticCheckInMinValueError}
            maxValue={automaticCheckInMaxValue}
            maxValueErrorText={automaticCheckInMaxValueError}
            onlyInteger={true}
            helperText="maximum days"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <NumberField
            id="automaticCheckinAirobotLimit"
            name="automaticCheckinAirobotLimit"
            label="Limit per day"
            disabled={!automaticCheckinAirobotEnabled}
            value={automaticCheckinAirobotLimit?.toString() ?? ''}
            minValue={automaticCheckInMinValue}
            minValueErrorText={automaticCheckInMinValueError}
            maxValue={automaticCheckInMaxValue}
            maxValueErrorText={automaticCheckInMaxValueError}
            onlyInteger={true}
            helperText="No, of created automated check-ins p.d."
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
