import { useId, useState } from 'react';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '../../../imports';

interface MultipleSelectCheckmarksProp {
  name: string;
  label: string;
  items: string[];
  onChange?: (selected: string[]) => void;
}

export default function MultipleSelectCheckmarks(props: MultipleSelectCheckmarksProp) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;

    // On autofill, we get a stringified value.
    const newSelectedOptions = typeof value === 'string' ? value.split(',') : value;
    newSelectedOptions.sort();

    setSelectedOptions(newSelectedOptions);

    props.onChange && props.onChange(newSelectedOptions);
  };

  const id: string = useId();
  const labelId: string = useId();

  return (
    <div>
      <FormControl sx={{ mb: 1, mt: 2, width: 300 }}>
        <InputLabel id={labelId}>{props.label}</InputLabel>
        <Select
          labelId={labelId}
          id={id}
          multiple
          name={props.name}
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}>
          {props.items.map((item: string) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedOptions.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
