import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
} from '../../imports';

import LoadingButton from '../../components/LoadingButton';
import LoadingPage from '../../components/LoadingPage';
import NumberMultiInput from '../../components/NumberMultiInput';
import SelectBrand from '../../components/SelectBrand';

import BrandProfileData from '../../models/brandProfile/BrandProfileData';
import BrandProfileFormData from '../../models/brandProfile/BrandProfileFormData';

import { ApiBrandProfileGet, ApiBrandProfileSet, ApiFunctionalitiesGet } from '../../constants/endpoints';
import { BrandProfileFormDataInit } from '../../constants/BrandProfileFormDataInit';
import { DataSendStatusInit } from '../../constants/DataSendStatus/DataSendStatusInit';

import { useAuth } from '../../services/useAuth';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import useAbortSignal from '../../services/useAbortSignal';
import useCurrentBrands from '../../services/useCurrentBrands';

export default function Brand() {
  const [formData, setFormData] = useState<BrandProfileFormData>(BrandProfileFormDataInit);
  const [loadedFormData, setLoadedFormData] = useState<BrandProfileFormData>(BrandProfileFormDataInit);
  const [loading, setLoading] = useState(true);
  const [crossSellingActive, setCrossSellingActive] = useState(true);
  const [sendingData, setSendingData] = useState(false);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const currentBrands = useCurrentBrands('single');
  const abortSignal = useAbortSignal();

  const currentBrand = currentBrands[0];

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  const updateLoadedFormData = useCallback(
    (data: BrandProfileFormData) => {
      setLoadedFormData({ ...data });
      setFormData({ ...data });
    },
    []
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // reset before request
    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    const bpd: BrandProfileData = {
      name: formData.name,
      company: formData.company,
      street: formData.street,
      number: formData.number,
      postcode: formData.postcode,
      city: formData.city,
      country: formData.country,
      telephone: formData.telephone,
      telefax: formData.telefax,
      emailB2c: formData.emailB2c,
      codes: formData.codes,
      subDomain: {
        emailFeedback: formData.subDomain_emailFeedback,
        emailPurchaseError: formData.subDomain_emailPurchaseError,
      },
      weblinks: {
        Website: formData.weblinks_Website,
        ContactForm: formData.weblinks_ContactForm,
        FAQs: formData.weblinks_FAQs,
        AppleApp: formData.weblinks_AppleApp,
        AndroidApp: formData.weblinks_AndroidApp,
        Hotel: formData.weblinks_Hotel,
        Car: formData.weblinks_Car,
        Insurance: formData.weblinks_Insurance,
        Parking: formData.weblinks_Parking,
        TravelCard: formData.weblinks_TravelCard,
        SearchForm: formData.weblinks_SearchForm,
        Flightright: formData.weblinks_Flightright,
        BannerLink: formData.weblinks_BannerLink,
        BannerImageDesktop: formData.weblinks_BannerImageDesktop,
        BannerImageMobile: formData.weblinks_BannerImageMobile,
        CovidTest: formData.weblinks_CovidTest,
        Co2Compensation: formData.weblinks_Co2Compensation,
      },
      functionalities: {
        hotjarId: formData.functionalities_hotjarId,
        useDataFromKim: formData.functionalities_useDataFromKim,
      },
    };

    axios
      .post(
        ApiBrandProfileSet,
        {
          brand: currentBrand.name,
          data: bpd,
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      )
      .then(() => {
        updateLoadedFormData(formData);
        setDataSendStatus({ open: true, success: true, message: 'Data updated' });
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const onKimChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.checked;
    setFormData({ ...formData, functionalities_useDataFromKim: newValue });
  };

  const onCrossSellingChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.checked;
    if (!newValue) {
      setFormData({
        ...formData,
        weblinks_BannerLink: '',
        weblinks_BannerImageDesktop: '',
        weblinks_BannerImageMobile: '',
      });
    }

    setCrossSellingActive(newValue);
  };

  const onCodesChange = (newValue: string[]) => {
    setFormData({ ...formData, codes: newValue });
  };

  const generateLinks = () => {
    return generateWeblinks(['Website', 'ContactForm', 'FAQs', 'AppleApp', 'AndroidApp']);
  };

  const generateCrossSelling = () => {
    return generateWeblinks([
      'Hotel',
      'Car',
      'Parking',
      'Insurance',
      'TravelCard',
      'SearchForm',
      'Flightright',
      'CovidTest',
      'Co2Compensation',
    ]);
  };

  const generateCrossSellingBanner = () => {
    return generateWeblinks(['BannerLink', 'BannerImageDesktop', 'BannerImageMobile'], !crossSellingActive);
  };

  const generateWeblinks = (keys: string[], disabled = false) => {
    const weblinks = {
      Website: 'Website',
      ContactForm: 'Contact form',
      FAQs: 'FAQs',
      AppleApp: 'IOS App link',
      AndroidApp: 'Android App link',
      Hotel: 'Hotel',
      Car: 'Car',
      Parking: 'Parking',
      Insurance: 'Insurance',
      TravelCard: 'Travel card',
      SearchForm: 'Search form',
      Flightright: 'Flightright',
      BannerLink: 'On Click - banner target URL',
      BannerImageDesktop: 'Desktop image URL',
      BannerImageMobile: 'Mobile image URL',
      CovidTest: 'Covid test',
      Co2Compensation: 'CO2 compensation',
    };
    const weblinksContent = [];
    let k: keyof typeof weblinks;
    let bpdk: keyof typeof formData;
    for (k in weblinks) {
      if (keys.indexOf(k) === -1) {
        continue;
      }

      let label: string = weblinks[k] as string;
      let value: string = '';
      for (bpdk in formData) {
        if (bpdk === 'weblinks_' + k) {
          value = formData[bpdk] as string;
          break;
        }
      }
      weblinksContent.push(
        <FormControl fullWidth margin="normal" key={'weblinks_' + k} disabled={disabled}>
          <InputLabel htmlFor={'weblinks_' + k}>{label}</InputLabel>
          <OutlinedInput
            fullWidth
            name={'weblinks_' + k}
            id={'weblinks_' + k}
            label={label}
            type="text"
            value={value}
            onChange={onChange}
          />
        </FormControl>
      );
    }

    return weblinksContent;
  };

  const loadFormData = useCallback(() => {
    setLoading(true);
    Promise.all([
      axios.post(
        ApiBrandProfileGet,
        {
          brand: currentBrand.name,
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      ),
      axios.post(
        ApiFunctionalitiesGet,
        {
          brand: currentBrand.name,
        },
        { headers: { 'Auth-User': user.token }, signal: abortSignal }
      ),
    ])
      .then(function (results) {
        const rd = results[0].data.data;
        const frd = results[1].data.data;

        const bpdLoaded = {
          name: (rd.name as string) ?? '',
          company: (rd.company as string) ?? '',
          street: (rd.street as string) ?? '',
          number: (rd.number as string) ?? '',
          postcode: (rd.postcode as string) ?? '',
          city: (rd.city as string) ?? '',
          country: (rd.country as string) ?? '',
          telephone: (rd.telephone as string) ?? '',
          telefax: (rd.telefax as string) ?? '',
          emailB2c: (rd.emailB2c as string) ?? '',
          codes: (rd.codes as string[]) ?? [],

          subDomain_emailFeedback: (rd.subDomain.emailFeedback as string) ?? '',
          subDomain_emailPurchaseError: (rd.subDomain.emailPurchaseError as string) ?? '',

          weblinks_Website: (rd.weblinks.Website as string) ?? '',
          weblinks_ContactForm: (rd.weblinks.ContactForm as string) ?? '',
          weblinks_FAQs: (rd.weblinks.FAQs as string) ?? '',
          weblinks_AppleApp: (rd.weblinks.AppleApp as string) ?? '',
          weblinks_AndroidApp: (rd.weblinks.AndroidApp as string) ?? '',
          weblinks_Hotel: (rd.weblinks.Hotel as string) ?? '',
          weblinks_Car: (rd.weblinks.Car as string) ?? '',
          weblinks_Insurance: (rd.weblinks.Insurance as string) ?? '',
          weblinks_Parking: (rd.weblinks.Parking as string) ?? '',
          weblinks_TravelCard: (rd.weblinks.TravelCard as string) ?? '',
          weblinks_SearchForm: (rd.weblinks.SearchForm as string) ?? '',
          weblinks_Flightright: (rd.weblinks.Flightright as string) ?? '',
          weblinks_BannerLink: (rd.weblinks.BannerLink as string) ?? '',
          weblinks_BannerImageDesktop: (rd.weblinks.BannerImageDesktop as string) ?? '',
          weblinks_BannerImageMobile: (rd.weblinks.BannerImageMobile as string) ?? '',
          weblinks_CovidTest: (rd.weblinks.CovidTest as string) ?? '',
          weblinks_Co2Compensation: (rd.weblinks.Co2Compensation as string) ?? '',

          functionalities_hotjarId: (frd.hotjarId as string) ?? '',
          functionalities_useDataFromKim: (frd.useDataFromKim as boolean) ?? '',
        };

        updateLoadedFormData(bpdLoaded);

        if (
          bpdLoaded.weblinks_BannerLink ||
          bpdLoaded.weblinks_BannerImageDesktop ||
          bpdLoaded.weblinks_BannerImageMobile
        ) {
          setCrossSellingActive(true);
        }
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [currentBrand, user.token, checkResponseError, updateLoadedFormData, abortSignal]);

  useEffect(() => {
    if (currentBrand) {
      loadFormData();
    }
  }, [currentBrand, loadFormData]);

  return (
    <>
      {!currentBrands.length ? (
        <SelectBrand message="Select a brand to change its profile data." />
      ) : (
        <>
          {loading && <LoadingPage />}
          {!loading && (
            <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
              <Grid container columns={2}>
                <Grid item lg={1} xs={2}>
                  <Card variant="island">
                    <CardHeader
                      title="Contact data"
                      action={
                        <FormControlLabel
                          control={
                            <Switch
                              name="functionalities_useDataFromKim"
                              checked={formData.functionalities_useDataFromKim}
                              onChange={onKimChange}
                            />
                          }
                          label="Use from KIM"
                        />
                      }
                    />
                    <CardContent>
                      {formData.functionalities_useDataFromKim && (
                        <Alert severity="info" sx={{ margin: 'normal' }}>
                          Contact data are used per customer number from kim in the booking page.
                        </Alert>
                      )}
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="name"
                          id="name"
                          label="Name"
                          type="text"
                          value={formData.name}
                          onChange={onChange}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="company">Company</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="company"
                          id="company"
                          label="Company"
                          type="text"
                          value={formData.company}
                          onChange={onChange}
                        />
                      </FormControl>
                      <Grid container>
                        <Grid item xs={10}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="street">Street</InputLabel>
                            <OutlinedInput
                              fullWidth
                              name="street"
                              id="street"
                              label="Street"
                              type="text"
                              value={formData.street}
                              onChange={onChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} sx={{ pl: 2 }}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="number">No</InputLabel>
                            <OutlinedInput
                              fullWidth
                              name="number"
                              id="number"
                              label="No"
                              type="text"
                              value={formData.number}
                              onChange={onChange}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={3}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="postcode">ZIP-code</InputLabel>
                            <OutlinedInput
                              fullWidth
                              name="postcode"
                              id="postcode"
                              label="ZIP-code"
                              type="text"
                              value={formData.postcode}
                              onChange={onChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={9} sx={{ pl: 2 }}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="city">City</InputLabel>
                            <OutlinedInput
                              fullWidth
                              name="city"
                              id="city"
                              label="City"
                              type="text"
                              value={formData.city}
                              onChange={onChange}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="country">Country</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="country"
                          id="country"
                          label="Country"
                          type="text"
                          value={formData.country}
                          onChange={onChange}
                        />
                      </FormControl>
                      <Grid container>
                        <Grid item xs={6} sx={{ pr: 1 }}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="telephone">Telephone</InputLabel>
                            <OutlinedInput
                              fullWidth
                              name="telephone"
                              id="telephone"
                              label="Telephone"
                              type="text"
                              value={formData.telephone}
                              onChange={onChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ pl: 1 }}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="telefax">Telefax</InputLabel>
                            <OutlinedInput
                              fullWidth
                              name="telefax"
                              id="telefax"
                              label="Telefax"
                              type="text"
                              value={formData.telefax}
                              onChange={onChange}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="emailB2c">Email B2C</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="emailB2c"
                          id="emailB2c"
                          label="Email B2C"
                          type="text"
                          value={formData.emailB2c}
                          onChange={onChange}
                        />
                      </FormControl>
                    </CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader title="Internal communication" />
                    <CardContent>
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="subDomain_emailFeedback">Email Feedback</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="subDomain_emailFeedback"
                          id="subDomain_emailFeedback"
                          label="Email Feedback"
                          type="text"
                          value={formData.subDomain_emailFeedback}
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="subDomain_emailPurchaseError">Email Purchase Error</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="subDomain_emailPurchaseError"
                          id="subDomain_emailPurchaseError"
                          label="Email Purchase Error"
                          type="text"
                          value={formData.subDomain_emailPurchaseError}
                          onChange={onChange}
                        />
                      </FormControl>
                    </CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader title="Links" />
                    <CardContent>{generateLinks()}</CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader title="Hotjar" />
                    <CardContent>
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="functionalities_hotjarId">Hotjar ID</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="functionalities_hotjarId"
                          id="functionalities_hotjarId"
                          label="Hotjar ID"
                          type="text"
                          value={formData.functionalities_hotjarId}
                          onChange={onChange}
                        />
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item lg={1} xs={2}>
                  <Card variant="island">
                    <CardHeader title="Travel agency numbers" />
                    <CardContent>
                      <NumberMultiInput
                        name="codes"
                        id="codes"
                        label="Agency number(s)"
                        value={formData.codes}
                        onChange={onCodesChange}
                      />
                    </CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader title="Cross selling links" />
                    <CardContent>{generateCrossSelling()}</CardContent>
                  </Card>

                  <Card variant="island">
                    <CardHeader
                      title="Cross selling banner"
                      action={<Switch checked={crossSellingActive} onChange={onCrossSellingChange} />}
                    />
                    <CardContent>{generateCrossSellingBanner()}</CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Stack direction="row" ml={1}>
                <LoadingButton text="Save" sendingData={sendingData} />

                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => updateLoadedFormData(loadedFormData)}
                  sx={{ ml: 2 }}>
                  Reset
                </Button>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
}
